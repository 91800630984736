const menu = {
  addenquiries: 'Nueva consulta',
  addips: 'Nueva televisión',
  addrecords: 'Nuevo expediente',
  addroles: 'Añadir roles',
  addsales: 'Nueva venta',
  agenda: 'Agenda',
  budgets: 'Tramitación',
  calendar: 'Calendario',
  detailarticles: 'Artículos',
  detailbudgets: 'Administrador Presupuesto',
  detailenquiries: 'Detalle de consulta',
  detailips: 'Detalle de televisión',
  detailrecords: 'Detalle de expediente',
  detailroles: 'Detalls de rols',
  detailsales: 'Detalle de venta',
  detailservices: 'Servicios',
  detailusers: 'Detalle de usuario',
  detailworkshop: 'Taller por expediente',
  editarticles: 'Flores expediente',
  editbudgets: 'Administrador Presupuesto',
  editenquiries: 'Editar consulta',
  editips: 'Editar televisión',
  editrecords: 'Editar expediente',
  editroles: 'Editar rol',
  enquiries: 'Consultas',
  enquiriesDashboard: 'Panel consultas',
  flowers: 'Flores',
  home: 'Inicio',
  information: 'Información',
  ips: 'Televisiones',
  logout: 'Salir',
  management: 'Gerencia',
  notFound: 'Página no encontrada',
  operator: 'Operador',
  password: 'Cambiar contraseña',
  reception: 'Recepción',
  records: 'Expedientes',
  sales: 'Ventas',
  scan: 'Escanear QR',
  settings: 'Configuraciones',
  user: 'Perfil de usuario',
  userProfile: 'Perfil',
  users: 'Usuarios',
  viewqr: 'QR',
  workshop: 'Taller',
};
export default menu;
