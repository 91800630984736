import { useCallback } from 'react';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { useProvidedAuth } from 'hooks/useProvidedAuth';
import { Permission } from 'models/UserRole';

export const usePermissions = (): {
  hasPermission: (data: SimpleUserRequestPermissionNames) => boolean;
} => {
  const { user } = useProvidedAuth();

  const hasPermission = useCallback(
    (permission) => !!user?.role.permissions?.some(
      (p: Permission) => p.name === permission.valueOf(),
    ),
    [user?.role.permissions],
  );

  return {
    hasPermission,
  };
};
