const settings = {
  blocks: {
    WHATSAPP_SETTINGS: 'WhatsApp',
  },
  loadError: 'Error en obtenir la configuració',
  saveError: 'Error en desar la configuració',
  saveSuccess: 'Configuració guardada',
  whatsapp: {
    authToken: 'Token d\'autenticació',
    destinationNumberPhone: 'Telèfon de destinació',
    language: 'Idioma',
    sourceNumberPhone: 'Número de telèfon d\'origen',
    templateName: 'Nom de la plantilla',
    test: 'Provar configuració',
    testError: 'Error enviant missatge',
    testSuccess: 'Missatge enviat correctament',
  },
};

export default settings;
