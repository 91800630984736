import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorToast, showSuccessToast } from 'utils/toasts';
import { Nullable } from 'utils/types/Nullable/types';

import { declarantNotificationsController } from 'config/apiFunus';
import { settingsController } from 'modules/settings/api';

import { WhatsappHook } from './types';

export const useWhatsapp = (): WhatsappHook => {
  const { t } = useTranslation();
  const [languages, setlanguages] = useState<Array<string>>([]);
  const [testStatus, setTestSuccess] = useState<Nullable<boolean>>(null);

  const sendMessage = useCallback(
    ({
      number, language, recordId,
    }) => declarantNotificationsController
      .sendWhatsapp({
        language, number, recordId,
      })
      .then((response) => {
        showSuccessToast(t('record.whatsapp.sendSuccess'));
        return response;
      })
      .catch((e) => {
        showErrorToast(t(`error.${e.message}`));
        throw e;
      }),
    [t],
  );

  const loadLanguages = useCallback(() => {
    declarantNotificationsController.getNotificationLanguages()
      .then((response) => {
        setlanguages(response);
        return response;
      })
      .catch((e) => {
        setlanguages(['CA', 'ES', 'EN']);
        showErrorToast(e.message);
      });
  }, []);

  const testSettings = useCallback(
    (d) => {
      setTestSuccess(null);
      settingsController.TestWhatsappSettings(d)
        .then((response) => {
          setTestSuccess(true);
          showSuccessToast(t('settings.whatsapp.testSuccess'));
          return response;
        })
        .catch((e) => {
          setTestSuccess(false);
          showErrorToast(t(e.message));
        });
    },
    [t],
  );

  return {
    languages,
    loadLanguages,
    sendMessage,
    testSettings,
    testStatus,
  };
};
