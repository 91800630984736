import classNames from 'classnames';
import { FormikProps } from 'formik';
import React, {
  FC, ReactElement, useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import config from 'config';

import { formatDate } from 'utils/dateManager';
import { checkPermission } from 'utils/permissions';

import CustomIcon from 'components/base/CustomIcon';
import { FeatherIconTypes } from 'components/base/CustomIcon/types';
import FilteredPage from 'components/base/FilteredPage';
import { displayNoValue } from 'components/base/NoData';
import SideFilterField from 'components/base/SideFilters/SideFilterField';
import { TableColumn } from 'components/base/Table/types';

import { EnquireStates } from 'components/enquiry/Form';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { useProvidedAuth } from 'hooks/useProvidedAuth';
import { i18n } from 'i18n';
import Enquiry, { SearchEnquiryProps } from 'models/Enquiry';

import { EnquiryAssignModal } from '../EnquiryAssignModal';

const fields = (
  formikProps: FormikProps<SearchEnquiryProps>,
): ReactElement[] => [
  <SideFilterField
    key="createdDate"
    filterType="date"
    formikProps={formikProps}
    name="createdDate"
    placeholder={`${i18n.t('common.logEntryDate')}`}
  />,
  <SideFilterField
    key="clientName"
    filterType="input"
    formikProps={formikProps}
    name="clientName"
    placeholder={`${i18n.t('common.name')}`}
  />,
  <SideFilterField
    key="firstSurname"
    filterType="input"
    formikProps={formikProps}
    name="firstSurname"
    placeholder={`${i18n.t('common.firstSurname')}`}
  />,
  <SideFilterField
    key="secondSurname"
    filterType="input"
    formikProps={formikProps}
    name="secondSurname"
    placeholder={`${i18n.t('common.secondSurname')}`}
  />,
  <SideFilterField
    key="phone"
    filterType="input"
    formikProps={formikProps}
    name="phone"
    placeholder={`${i18n.t('common.phone')}`}
  />,
  <SideFilterField
    key="email"
    filterType="input"
    formikProps={formikProps}
    name="email"
    placeholder={`${i18n.t('common.email')}`}
  />,
  <SideFilterField
    key="comment"
    filterType="input"
    formikProps={formikProps}
    name="comment"
    placeholder={`${i18n.t('common.observations')}`}
  />,
];

const EnquiryTable: FC = () => {
  const { user } = useProvidedAuth();
  const { t } = useTranslation();
  const [showAssign, setShowAssign] = useState<boolean>(false);
  const [selectedEnquiry, setselectedEnquiry] = useState<Enquiry>();
  const [reFetch, setReFetch] = useState<() => void>();

  const columns = useMemo(
    (): TableColumn<Enquiry>[] => [
      { accessor: 'id', Header: `${t('common.id')}`, hidden: false },
      {
        accessor: 'createdDate',
        Cell: ({ cell: { value } }) => formatDate(value),
        Header: `${t('common.date')}`,
        sortable: true,
      },
      {
        accessor: 'clientName',
        Cell: ({
          row: {
            original: { clientName, firstSurname, secondSurname },
          },
        }) => classNames(clientName, firstSurname, secondSurname) || '',
        className: 'primary-dark-color',
        Header: `${t('common.name')}`,
        sortable: true,
      },
      {
        accessor: 'phone',
        Header: `${t('common.phone')}`,
        sortable: true,
      },
      { accessor: 'email', Header: `${t('common.email')}`, sortable: true },
      {
        accessor: 'comment',
        collapse: true,
        Header: `${t('common.observations')}`,
        sortable: true,
      },
      {
        accessor: 'status',
        Cell: ({ cell: { value } }) => EnquireStates.find(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (e: any) => e.code === value,
        )?.description,
        collapse: true,
        Header: `${t('enquiry.status')}`,
        sortable: true,
      },
      {
        accessor: 'advisor',
        Cell: ({ cell: { value } }) => {
          if (value) return `${value?.name} ${value?.firstSurname} ${value?.secondSurname}`;
          return displayNoValue;
        },
        collapse: true,
        Header: `${t('enquiry.advisor')}`,
        sortable: true,
      },
    ],
    [t],
  );

  return (
    <React.Fragment>
      <FilteredPage<SearchEnquiryProps, Enquiry>
        actions={(row: Enquiry) => ({
          assignEnquiry: checkPermission(
            SimpleUserRequestPermissionNames.ENQUIRY_ASSIGN,
            user?.role.permissions,
          ) ? {
              enquiry: row,
              icon: <CustomIcon icon={FeatherIconTypes.USERPLUS} />,
              onClick: () => {
                setselectedEnquiry(row);
                setShowAssign(true);
              },
              tooltipCaption: t('user.assignUser'),
            }
            : undefined,
          edit: checkPermission(
            SimpleUserRequestPermissionNames.ENQUIRY_WRITE,
            user?.role.permissions,
          ) ? {
              icon: <CustomIcon icon={FeatherIconTypes.EDIT} />,
              tooltipCaption: t('common.edit'),
              url: `${config.url.enquiries}/${row.id}/edit`,
            } : undefined,
        })}
        apiCall={config.apiFunus.enquiries.setEnquiries}
        columns={columns}
        create={
        checkPermission(SimpleUserRequestPermissionNames.ENQUIRY_ADD, user?.role.permissions)
          ? {
            title: t('enquiry.new'),
            url: config.url.newEnquiry,
          }
          : undefined
      }
        fields={fields}
        initialValues={{}}
        text={{
          search: t('enquiry.search'),
          title: t('enquiry.title'),
        }}
        onRefetchUpdate={(callback) => {
          setReFetch(() => callback);
        }}
      />
      <EnquiryAssignModal
        enquiry={selectedEnquiry}
        show={showAssign}
        onHide={() => {
          setShowAssign(false);
        }}
        onSuccess={() => {
          reFetch?.();
          setShowAssign(false);
        }}
      />
    </React.Fragment>
  );
};

export default EnquiryTable;
