import React, {
  FC, useCallback, useEffect, useState,
} from 'react';

import InputQRScan from 'components/base/InputQRScan';

import { PersonaItemsOverrideModal } from '../OverrideDecisionModal';
import { useDeliveryObjects } from '../useDeliveryObjects';

import { DeliveryObjectConntentComponent } from './types';

export const DeliveryObjectContent: FC<DeliveryObjectConntentComponent> = (
  { recordId, onChange, onCancel },
) => {
  const [showOverrideModal, setShowOverrideModal] = useState<boolean>(false);
  const [dataQR, setDataQR] = useState<string>('');
  const [overwrite, setOverwrite] = useState<boolean>();
  const { deliveryObjects, getDeliveryObjects } = useDeliveryObjects();

  const handleChange = useCallback((qr: string) => {
    setDataQR(qr);
    if (overwrite) {
      onChange([qr]);
    } else if (deliveryObjects) {
      const updatedQrs = deliveryObjects.slice();
      updatedQrs.push(qr);
      onChange(updatedQrs);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [deliveryObjects, overwrite]);

  useEffect(() => {
    getDeliveryObjects(recordId.toString());
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  useEffect(
    () => {
      if (deliveryObjects) {
        onChange(deliveryObjects);
        if (deliveryObjects.length > 0) {
          setShowOverrideModal(true);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deliveryObjects],
  );

  return (
    <React.Fragment>
      <InputQRScan
        data={dataQR}
        setData={(qr) => {
          handleChange(qr.toString());
        }}
      />
      <PersonaItemsOverrideModal
        show={showOverrideModal}
        onAccept={(overwriteQrs: boolean) => {
          setOverwrite(overwriteQrs);
          setShowOverrideModal(false);
        }}
        onCancel={() => {
          setShowOverrideModal(false);
          onCancel();
        }}
      />
    </React.Fragment>
  );
};
