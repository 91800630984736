import {
  ContentType,
  HttpClient,
  RequestParams,
} from 'config/apiFunus/generated/http-client';
import { IAPIFunusSettings } from 'modules/settings/models/IAPIFunusSettings';
import { IFunusSettings } from 'modules/settings/models/IFunusSettings';

import { ITestSettingsRequest } from './ITestSettingsRequest';

export class SettingsClient<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  UpdateSettings = async (
    data: IFunusSettings,
    params: RequestParams = {},
  ): Promise<void> => {
    const body: IAPIFunusSettings = {
      whatsappSettingsDto: data.whatsapp,
    };

    return this.http
      .request<void, void>({
        body,
        method: 'PUT',
        path: '/settings',
        secure: true,
        type: ContentType.Json,
        ...params,
      });
  }

  GetSettings = async (
    params: RequestParams = {},
  ): Promise<IFunusSettings> => this.http
    .request<IAPIFunusSettings, void>({
      method: 'GET',
      path: '/settings',
      secure: true,
      type: ContentType.Json,
      ...params,
    }).then((respnse) => ({
      whatsapp: {
        authToken: respnse.whatsappSettingsDto.authToken,
        sourceNumberPhone: respnse.whatsappSettingsDto.sourceNumberPhone,
        templateName: respnse.whatsappSettingsDto.templateName,
      },
    }))

    TestWhatsappSettings = async (
      data: ITestSettingsRequest,
      params: RequestParams = {},
    ): Promise<void> => this.http
      .request<void, void>({
        body: data,
        method: 'POST',
        path: '/settings/whatsappTest',
        secure: true,
        type: ContentType.Json,
        ...params,
      })
}
