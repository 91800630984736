import React, { FC, useState } from 'react';

import InputQRScan from 'components/base/InputQRScan';

import { MultipleScanItemComponent } from './types';

export const MultipleScanItem: FC<MultipleScanItemComponent> = (
  {
    onChange, disabled, placeholder, name,
  },
) => {
  const [data, setData] = useState<string>('');
  return (
    <InputQRScan
      data={data}
      disabled={disabled}
      name={name}
      placeholder={placeholder}
      setData={(qr) => {
        setData(qr);
        onChange(qr.toString());
      }}
    />

  );
};
