import { Form, Formik, FormikProps } from 'formik';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { useValidator } from 'hooks/useValidator';
import { IFunusSettings } from 'modules/settings/models/IFunusSettings';

import { SettingsTabs } from './settings-tabs';
import { SettingsTabOptions } from './settings-tabs/SettingsTabOptions';
import { ISettingsForm } from './types';
import { SettingsWhatsappForm } from './whatsapp';
import './index.scss';

export const SettingsForm: FC<ISettingsForm> = ({ onSubmit, value, onChange }) => {
  const { t } = useTranslation();
  const { validateSettings } = useValidator();
  const [
    selectedTab,
    setSelectedtab,
  ] = useState<SettingsTabOptions>(SimpleUserRequestPermissionNames.WHATSAPP_SETTINGS);

  return (
    <div className="form-page f-settings-form">
      <Formik
        initialValues={{
          ...value,
        }}
        validate={((values) => {
          onChange(values);
          return validateSettings(values);
        })}
        enableReinitialize
        validateOnBlur
        validateOnChange
        onSubmit={onSubmit}
      >
        {({
          values,
          handleBlur,
          setValues,
          errors,
        }: FormikProps<IFunusSettings>) => {
          const canSave = !!values.whatsapp.authToken
        && !!values.whatsapp.sourceNumberPhone
        && !!values.whatsapp.templateName;

          return (
            <Form autoComplete="off" className="form-generator">
              <SettingsTabs
                value={selectedTab}
                onChange={(tab: SettingsTabOptions) => setSelectedtab(tab)}
              />
              {selectedTab === SimpleUserRequestPermissionNames.WHATSAPP_SETTINGS
                && (
                <SettingsWhatsappForm
                  errors={errors}
                  handleBlur={handleBlur}
                  value={values.whatsapp}
                  onChange={(v) => {
                    setValues({
                      ...values,
                      whatsapp: v,
                    });
                  }}
                />
                )}
              <div className="buttons-panel">
                <Button
                  color="primary"
                  disabled={!canSave}
                  text={t('common.save')}
                  type="submit"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
