const order = {
  addDeceasedQR: 'Afegir QR del difunt',
  addDeceasedQRKO: 'Error en afegir QR de difunt',
  addDeceasedQROK: ' QR de difunt actualitzat amb èxit',
  adviserComment: 'Comentaris assesor',
  andOther: 'tasques més que no s\'estan mostrant',
  assigned: 'Assignada',
  create: 'Crear ordre manual',
  created: 'Ordre creada amb èxit!',
  createError: 'No s\'ha pogut crear l\'ordre',
  deceasedName: 'Nom del difunt',
  deceasedSurname: 'Cognom del difunt',
  deposit: 'Dipòsit',
  details: {
    cemetery: 'Cementiri',
    cemeteryDestination: 'Destí del cementiri',
    cemeteryOrigin: 'Cementiri origen',
    ceremonial: 'Cerimonial',
    ceremonyLocation: 'Cerimònia Ubicació',
    ceremonySchedule: 'Calendari de cerimònies',
    coldRoom: 'Cambra frigorífica',
    collection: 'Col · lecció',
    creationHour: 'Hora de creació',
    cremationTime: 'Hora d\'incineració',
    deceasedLocation: 'Ubicació morta',
    defunctStatus: 'Estat Difunt',
    deliveryLocation: 'Ubicació de lliurament',
    description: 'Descripció',
    design: 'Desseny',
    destination: 'Destí',
    destinationCenter: 'Centre de destí',
    detail: 'Detall',
    details: 'Detalls',
    documentationDelivered: 'Documentació lliurada',
    finalDestination: 'Destinació final',
    funerariComment: 'Comentari Funerari',
    location: 'Ubicació',
    mortuary: 'Funerària',
    niche: 'Sepultura',
    nicheDestination: 'Sepultura de destinació',
    nicheOrigin: 'Sepultura origen',
    noDetails: 'S\'ha produït un error en carregar les dades',
    origin: 'Origen',
    outfitting: 'Equipament',
    oven: 'Forn',
    sanitaryPracticeAuthorization: 'Autorització de pràctiques sanitàries',
    sanitaryPractices: 'Pràctiques sanitàries',
    schedule: 'Calendari',
    target: 'Objectiu',
    transfer: 'Transferència',
    transport: 'Transport',
    type: 'Tipus',
    urn: 'Urn',
    vehicle: 'Vehicle',
    vigilDate: 'Data de Vetlla',
    vigilHour: 'Hora de Vetlla',
    vigilLocation: 'Lloc de Vetla',
    vigilRoom: 'Vetlla',
  },
  driver: 'Conductor',
  edit: 'Edita ordre manual',
  enums: {
    info: {
      ADOL: 'Adol',
      AFEITADO: 'Afaitar',
      AVION: 'Avió',
      CAMARA: 'Camara',
      CAMARA_FRIGORIFICA: 'Cambra frigorifica',
      CARRETERA: 'Carretera',
      CELOFANA: 'Celofana',
      CEMENTERIO: 'Cementeri',
      CENTRO: 'Center',
      CREMATORIO: 'Crematori',
      DOMICILIO: 'Domicili',
      ENTREGA: 'Lliurament',
      ESTANDAR: 'Estándard',
      FERETRO: 'Fèretre',
      HOSPITAL: 'Hospital',
      INSTALACION: 'Instal·lació',
      JUDICIAL: 'Judicial',
      MAQUILLAJE: 'Maquillatge',
      MARCAPASOS: 'Marcapassos',
      OBJETOS_PERSONALES: 'Objectes personals',
      PEINADO: 'Pentinar',
      PRACTICA_SANITARIA: 'Practica sanitária',
      RESIDENCIA: 'Resicéncia',
      SERRADURES_SULFAT: 'Serradures sulfat',
      TAPONAR: 'Taponar',
      VESTIMENTA: 'Vestir',
    },
    priority: {
      HIGH: 'Alta',
      NORMAL: 'Normal',
      URGENT: 'Urgent',
    },
  },
  finished: 'Ordre finalitzada amb èxit!',
  finishOrder: 'Finalitzar ordre',
  getError: 'Hi ha hagut un problema a l\'portar les dades de l\'ordre!',
  noOptions: 'No hi ha opcions',
  noOrders: 'No hi ha ordres',
  notAssigned: 'No assignada',
  notFinished: 'Error a l\'intentar finalitzar l\'ordre',
  notStarted: 'Error a l\'intentar començar l\'ordre',
  priority: 'Prioritat',
  qr: {
    coffin: {
      empty: 'El QR del taüt no informat ',
      mismatch: 'El QR informat del taüt no coincideix amb el qr de taüt associat al difunt',
      occupied: 'El QR informat del taüt pertany a un altre difunt',
    },
    coffinQr: 'Escanegeu el QR associat al taüt',
    deceased: {
      empty: 'El QR del difunt no informat',
      mismatch: 'El QR informat del difunt no coincideix amb el qr associat al difunt',
      occupied: 'El QR informat del difunt pertany a un altre difunt',
    },
    deceasedQr: 'Escanegeu el QR associat al difunt',
    error: {
      alreadyAssigned: 'Ja està associat a un altre expedient.',
      coffin: 'El QR del taüt no és correcte.',
      deceased: 'El QR del difunt no és correcte.',
      differentValue: 'No correspon a l\'apropiat.',
      personalItems: 'El QR dels objectes personals no és correcte.',
      urn: 'El QR de l\'urna no és correcte.',
    },
    mismatch: 'El QR informat d\'objectes personals no coincideix amb el qr d\'objectes personals associat al difunt',
    occupied: {
      coffin: 'El QR de feretre ja s\'ha utilitzat en aquest expedient o en un altre',
      deceased: 'El QR de polsera ja s\'ha fet servir en aquest o altre expedient.',
      personalitems: 'El QR d\'objectes personals ja s\'ha utilitzat en aquest expedient o en un altre',
      urn: 'El QR d\'urna ja s\'ha utilitzat en aquest expedient o en un altre.',
    },
    personalitems: {
      empty: 'El QR d\'objectes personals no informat',
      mismatch: 'El QR informat d\'objectes personals no coincideix amb el qr d\'objectes personals associat al difunt',
    },
    personalItemsQr: 'Escanegeu el QR associat als objectes personals',
    personalItemsQrs: 'Escanegeu els QR associats als objectes personals',
    urn: {
      empty: 'QR d\'urna no informat',
      occupied: 'QR informat d\'urna pertany a un altre difunt',
    },
    urnQr: 'Escanegeu el QR associat a l\'urna',
  },
  qrs: {
    mismatch: 'El QR informat d\'objectes personals no coincideix amb el qr d\'objectes personals associat al difunt',
  },
  record: 'Expedient',
  remove: 'Eliminar ordre manual',
  removed: 'Ordre eliminat correctament!',
  removeError: 'Error a l\'intentar eliminar l\'ordre',
  removeSure: 'Estàs segur que vols eliminar la ordre?',
  selectPriority: 'Filtrar per prioritat',
  showAll: 'Mostrar totes',
  started: 'Ordre començada amb èxit!',
  startOrder: 'Començar ordre',
  status: {
    completed: 'Finalitzades',
    inProgress: 'En curs',
    pending: 'Pendent',
  },
  types: {
    CEREMONIA: 'Cerimònia',
    INCINERACION: 'Incineració',
    INHUMACION: 'Inhumació',
    INTERNAL_MOVEMENTS: 'Moviment intern',
    LAPIDA: 'Làpida',
    MANUAL_CEMENTERIO: 'Manual cementiri',
    MANUAL_TALLER: 'Manual taller',
    MOVIMIENTO: 'Moviment',
    MOVIMIENTO_INTERNO: 'Moviment intern',
    POST_CEREMONIA: 'Post-Cerimònia',
    PRACTICA_SANITARIA: 'Practica sanitária',
    PREPARACION: 'Preparació',
    PREPARACION_NICHO: 'Preparació nínxol',
    RECEPCION: 'Recepció',
    RECOGIDA: 'Recollida',
    TRANSPORTE: 'Trasllat',
    TRASLADO: 'Trasllat',
    TRASLADO_RESTOS: 'Trasllat restes',
    VELATORIO: 'Vetlla',
  },
  updated: 'Ordre actualitzada!',
  updateError: 'Error a l\'intentar actualitzar l\'ordre',
  urn: {
    deceased: {
      mismatch: 'El QR informat d\'urna no coincideix amb el qr d\'urna associat al difunt',
    },
  },
  usersError: 'Hi ha hagut un problema a l\'intentar actualitzar els usuaris assignats',
  usersUpdated: 'Usuaris assignats actualitzats amb èxit!',
  vehicle: 'Vehicle',
  workOrders: 'Ordres de treball',
};

export default order;
