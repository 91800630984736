import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorToast, showSuccessToast } from 'utils/toasts';

import { settingsController } from 'modules/settings/api';
import { IFunusSettings } from 'modules/settings/models/IFunusSettings';
import { emptyFunusSettings } from 'modules/settings/pages/settings/emptyFunusSettings';

export const useSettings = (): {
  settings: IFunusSettings;
  loadSettings: () => void;
  saveSettings: (v: IFunusSettings) => void;
} => {
  const { t } = useTranslation();
  const [settings, setSettings] = useState<IFunusSettings>(emptyFunusSettings);

  const loadSettings = useCallback(
    () => {
      settingsController.GetSettings()
        .then((response) => {
          setSettings(response);
          return response;
        })
        .catch(() => showErrorToast(t('settings.loadError')));
    },
    [t],
  );

  const saveSettings = useCallback(
    (v: IFunusSettings) => {
      settingsController.UpdateSettings(v)
        .then(() => showSuccessToast(t('settings.saveSuccess')))
        .catch(() => showErrorToast(t('settings.saveError')));
    },
    [t],
  );

  return {
    loadSettings,
    saveSettings,
    settings,
  };
};
