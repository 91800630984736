import article from 'i18n/cat/article';
import budget from 'i18n/cat/budget';
import common from 'i18n/cat/common';
import enquiry from 'i18n/cat/enquiry';
import error from 'i18n/cat/error';
import information from 'i18n/cat/information';
import ip from 'i18n/cat/ip';
import menu from 'i18n/cat/menu';
import notifications from 'i18n/cat/notifications';
import order from 'i18n/cat/order';
import qr from 'i18n/cat/qr';
import record from 'i18n/cat/record';
import role from 'i18n/cat/role';
import sale from 'i18n/cat/sale';
import service from 'i18n/cat/service';
import user from 'i18n/cat/user';
import validationRules from 'i18n/cat/validationRules';

import ia from './cat/ia';
import settings from './cat/settings';

export default {
  article,
  budget,
  common,
  enquiry,
  error,
  ia,
  information,
  ip,
  menu,
  notifications,
  order,
  qr,
  record,
  role,
  sale,
  service,
  settings,
  user,
  validationRules,
};
