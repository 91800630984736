const role = {
  delete: 'Eliminar rol',
  duplicated: 'Error, duplicado',
  fakeEmail: 'Error, email incorrecto',
  noDescription: 'Sin descripción',
  permissions: {
    ADVICE_ADD: 'AÑADIR SERVICIOS',
    ADVICE_INFO: 'VISUALIZAR INFORMATIVO',
    ADVICE_PERMISSION_GROUP: 'Permisos relacionados con los servicios de un expediente',
    ADVICE_READ: 'VISUALIZAR SERVICIOS',
    ADVICE_WRITE: 'MODIFICAR SERVICIOS',
    APPOINTMENT: 'ASIGNAR FECHA DE CITA PREVÍA',
    ARTÍCULO_ADD: 'AÑADIR FLORES',
    ARTÍCULO_CANCEL: 'ANULAR PEDIDOS',
    ARTÍCULO_DELETE: 'ELIMINAR PEDIDO FLORES',
    ARTÍCULO_DISAGREEMENT: 'CREAR DISCONFORMIDAD',
    ARTÍCULO_NOTIFY: 'NOTIFICACIÓN DE FLOR EN EL CARRO',
    ARTÍCULO_PERMISSION_GROUP: 'Permisos de Flores',
    ARTÍCULO_QR_READ: 'LEER QR FLORS',
    ARTÍCULO_READ: 'VISUALIZAR PANTALLA FLORES',
    ARTÍCULO_SUPPLIER: 'PERMIS DEL PROVEEDOR PARA ACEPTAR',
    ASHES_DELIVERY: 'ENTREGAR CENICAS',
    BUDGET_ARTICLE_MANAGEMENT: 'MODIFICAR ARTÍCULOS DE PRESUPUESTOS',
    BUDGET_PERMISSION_GROUP: 'Permisos de presupuesto',
    BUDGET_RULES_WRITE: 'ADMINISTRAR ARTÍCULOS',
    BUDGET_WRITE: 'CREAR PRESUPUESTOS',
    COLLECTION_NOTIFY: 'NOTIFICACIÓN DE RECOGIDA',
    ENQUIRY_ADD: 'AÑADIR CONSULTAS',
    ENQUIRY_ASSIGN: 'ASIGNAR CONSULTAS',
    ENQUIRY_NOTIFY: 'NOTIFICACIÓN DE NUEVA CONSULTA',
    ENQUIRY_PERMISSION_GROUP: 'Permisos de Consultas ',
    ENQUIRY_READ: 'VISUALIZAR CONSULTAS',
    ENQUIRY_WRITE: 'MODIFICAR CONSULTAS',
    NOTIFY_PERMISSION_GROUP: 'Permisos de Notificaciones',
    RECORD_ADD: 'AÑADIR EXPEDIENTES',
    RECORD_ADD_ENQUIRY: 'CREAR EXPEDIENTES DE PRENECESIDAD',
    RECORD_ADD_NOTICE: 'AÑADIR EXPEDIENTES CON POCAS DATOS',
    RECORD_ASSIGN: 'ASIGNAR ASESORES A UN EXPEDIENTE',
    RECORD_DELETE: 'ELIMINAR EXPEDIENTE',
    RECORD_NOTIFY: 'NOTIFICACIÓN DE NUEVO EXPEDIENTE',
    RECORD_PERMISSION_GROUP: 'Permisos relacionados con expedientes',
    RECORD_QR_READ: 'CONSULTAR QR',
    RECORD_READ: 'VISUALIZAR EXPEDIENTES',
    RECORD_WRITE: 'MODIFICAR EXPEDIENTES',
    SETTINGS_PERMISSION_GROUP: 'CONFIGURACIONES',
    USER_ADD: 'AÑADIR USUARIO',
    USER_PERMISSION_GROUP: 'Permisos de administrador',
    USER_READ: 'VISUALIZAR ADMINISTRADOR',
    USER_WRITE: 'MODIFICAR USUARIO',
    WHATSAPP_SETTINGS: 'WHATSAPP',
    WORK_ORDER_CEMETERY_ADD: 'ASIGNAR OT SEPULTURERS (Coord. sepul)',
    WORK_ORDER_CEMETERY_READ: 'VISUALIZAR OTS CEMENTERIO',
    WORK_ORDER_CEMETERY_WRITE: 'REALIZADO OTS CEMENTERIO',
    WORK_ORDER_PERMISSION_GROUP: 'Permisos de órdenes de trabajo',
    WORK_ORDER_WORKSHOP_ADD: 'ASIGNAR OT FUNERARIOS (Coord. fune)',
    WORK_ORDER_WORKSHOP_READ: 'VISUALIZAR OTS TALLER',
    WORK_ORDER_WORKSHOP_WRITE: 'REALIZADO OTS TALLER',
  },
  saveKo: 'Error al intentar guardar el rol',
  saveOk: '¡El rol ha sido guardado con éxito!',
  search: 'Buscar',
  sureDelete: '¿Estás seguro que quieres eliminar este rol?',
};

export default role;
