import classNames from 'classnames';
import React, {
  FC,
  useEffect,
  useState,
} from 'react';

import './index.scss';

import { PERSONAL_ITEMS_QR_GLU } from 'components/workOrder/Card/ChangeStatus/PERSONAL_ITEMS_QR_GLU';

import { MultipleScanItem } from './MultipleScanItem';
import { InputMultipleQRScanComponent } from './types';

export const InputMultipleQRScan: FC<InputMultipleQRScanComponent> = ({
  disabled,
  quantity,
  error,
  placeholder,
  onChange,
}) => {
  const [qrCodes, setQrCodes] = useState<Array<string>>(new Array(quantity).fill(''));

  useEffect(
    () => {
      onChange(qrCodes.join(PERSONAL_ITEMS_QR_GLU));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [qrCodes],
  );

  const scanQrItems = [];
  for (let index = 0; index < quantity; index++) {
    scanQrItems.push(<MultipleScanItem
      // eslint-disable-next-line react/no-array-index-key
      key={`multiple-scan-item-${index}`}
      disabled={disabled}
      name={`multiple-scan-item-${index}`}
      placeholder={placeholder}
      onChange={(qrCode) => {
        const updatedQrCodes = [...qrCodes];
        updatedQrCodes[index] = qrCode;
        setQrCodes(updatedQrCodes);
      }}
    />);
  }

  return (
    <div className={classNames('funus-input', 'InputMultipleQRScan', { error: !!error })}>
      {scanQrItems}
      <span className={classNames({ hidden: !error })}>{error}</span>
    </div>
  );
};
