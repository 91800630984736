import { Declarant } from "models/Declarant";
import { ContentType, HttpClient, RequestParams } from "../generated/http-client";
import { QRDto } from "models/QRDto";

export class DeliveryObjectsController<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  updateDeliveryObjects = (data: {
    recordId: string;
    qr: Array<QRDto>;
  }, params: RequestParams = {}) =>
    this.http.request<void, void>({
      path: `/record/${data.recordId}/qrs`,
      method: "PUT",
      body: data.qr,
      secure: true,
      type: ContentType.Json,
      ...params,
    });

  getDeliveryObjects = (data: {
    recordId: string;
  }, params: RequestParams = {}) =>
    this.http.request<{ qrs: Array<QRDto> }, void>({
      path: `/record/${data.recordId}`,
      method: "GET",
      secure: true,
      type: ContentType.Json,
      ...params,
    })
      .then((response) => {
        return response.qrs;
      });
}
