export default {
  agendaNoInfo: '/agenda',
  agenda: '/agenda/:year/:month/:day',
  articleInfo: '/articles/info',
  articlePhoto: '/articlePhoto',
  budgetsTracking: '/budgets',
  budgetsArticles:'/budgets/articles',
  budgetsArticleRules:'/budgets/article/rules',
  budgetsArticleIdRules:'/budgets/article/:code/rules',
  budgetsWizardNew: '/budget/wizard',
  budgetsWizard: '/budget/wizard/:budgetId/:questionCode',
  budgetSummary: '/budget/summary/:budgetId',
  calendarNoInfo: '/calendar',
  calendar: '/calendar/:year/:month',
  changePassword: '/password',
  enquiries: '/enquiries',
  enquiriesDashboard: '/enquiriesDashboard',
  flowersTraking: '/articles/flowers',
  flowersTrakingByRecord: '/articles/flowers/:erpid',
  forgot: '/forgot',
  information: '/information',
  settings: '/settings',
  ips: '/ips',
  landing: '/',
  newEnquiry: '/enquiries/add',
  newIp: '/ips/add',
  newRecord: '/records/add',
  newRole: '/roles/add',
  newSale: '/sales/add',
  newUser: '/newuser',
  newUsers: '/users/add',
  records: '/records',
  roles: '/roles',
  sales: '/sales',
  scan: '/scan',
  services: '/services',
  userProfile: '/user',
  users: '/users',
  viewqr: '/viewqr',
  workshop: '/workshop'
};
