const qr = {
  coffinQr: 'QR Taüt',
  occupied: {
    coffin: 'El QR de feretre ja s\'ha utilitzat en aquest expedient o en un altre',
    deceased: 'El QR de polsera ja s\'ha fet servir en aquest o altre expedient.',
    personalitems: 'El QR d\'objectes personals ja s\'ha utilitzat en aquest expedient o en un altre',
    urn: 'El QR d\'urna ja s\'ha utilitzat en aquest expedient o en un altre.',
  },
  personalItemsQr: 'QR Objectes personals',
  qr: 'QR Polsera',
  search: 'Cercar consultes',
  title: 'Consultes',
  urnQr: 'QR Urna',
};

export default qr;
