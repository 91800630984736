import classNames from 'classnames';
import React, {
  FC,
  Fragment,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import config from 'config';

import logo from 'assets/images/logo_funus.svg';

import { formatDate } from 'utils/dateManager';
import { sortWorkorders } from 'utils/workorderUtil';

import Button from 'components/base/Button';
import Loader from 'components/base/Loader';
import Message from 'components/base/Message';
import { tristateEquivalence } from 'components/base/SideFilters/SideFilterField';
import TopInfo from 'components/base/TopInfo';

import RecordTopInfo from 'components/record/TopInfo';
import SelectOrderMobile from 'components/workOrder/SelectOrderMobile';
import WorkOrderGroup from 'components/workOrder/WorkOrderGroup';

import { TelevisionNotExistsError } from 'config/apiErrors/TelevisionNotExistsError';
import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { CenterType } from 'hooks/CenterType';
import { useCenters } from 'hooks/useCenters';
import { useGeolocation } from 'hooks/useGeolocation';
import { useProvidedAuth } from 'hooks/useProvidedAuth';
import useWindowSize from 'hooks/useWindowSize';
import { useWorkshopRefresh } from 'hooks/useWorkshopRefresh';
import {
  Centers,
  useWorkshopTvView,
} from 'hooks/useWorkshopTvView';
import { WorkshopPageParams } from 'hooks/WorkshopPageParams';
import { WorkshopTypes } from 'hooks/WorkshopTypes';
import { i18n } from 'i18n';
import './index.scss';
import { StatusCodes } from 'models/OrderStatus';
import { Permission } from 'models/UserRole';
import Workorder from 'models/Workorder';

import { WorkOrderFilter, WorkshopFilter } from './WorkOrderFilter';

enum ListStatusCode {
  pending = 'pending',
  inProgress = 'inProgress',
  completed = 'completed',
}

type ItemsType = {
  pending: Workorder[];
  inProgress: Workorder[];
  completed?: Workorder[];
};

const initItems: ItemsType = {
  completed: [],
  inProgress: [],
  pending: [],
};

const getPriorityOptions = () => [
  {
    label: i18n.t('order.showAll'),
    value: 'ALL',
  },
  {
    label: i18n.t('order.enums.priority.NORMAL'),
    value: 'NORMAL',
  },
  {
    label: i18n.t('order.enums.priority.HIGH'),
    value: 'HIGH',
  },
  {
    label: i18n.t('order.enums.priority.URGENT'),
    value: 'URGENT',
  },
];

const WorkshopPage: FC = () => {
  const { t } = useTranslation();
  const { IPv4 } = useGeolocation();
  const { orderId } = useParams<WorkshopPageParams>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const priorityOptions = useMemo(() => getPriorityOptions(), [t]);
  const [isLoading, setIsLoading] = useState(true);
  const [tvAllowed, setTvAllowed] = useState(true);
  const [wrongCenter, setWrongCenter] = useState(true);
  const [buttonsState, setButtonsState] = useState(1);
  const [priorityState, setPriorityState] = useState(priorityOptions[0]);
  const [items, setItems] = useState<ItemsType>(initItems);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [record, setRecord] = useState<any>();
  const { lgOrSmaller } = useWindowSize();
  const { user } = useProvidedAuth();
  const [currentFilters, setCurrentFilters] = useState<WorkshopFilter>({});
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [ip, setIP] = useState<string>();
  const { hierarchy } = useCenters();
  useEffect(() => {
    setIP(IPv4);
  }, [IPv4]);

  const {
    isTvView,
    id,
    type,
  } = useWorkshopTvView();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [kindListOrders, setkindListOrders] = useState<any>([]);

  useEffect(() => {
    if (isTvView) {
      setkindListOrders([
        { code: ListStatusCode.pending, id: 1 },
        { code: ListStatusCode.inProgress, id: 2 },
      ]);
    } else {
      setkindListOrders([
        { code: ListStatusCode.pending, id: 1 },
        { code: ListStatusCode.inProgress, id: 2 },
        { code: ListStatusCode.completed, id: 3 },
      ]);
    }
  }, [isTvView]);

  const methodFindWorkOrders = useCallback(() => {
    setIsLoading(true);
    if (isTvView === false) {
      setRecord(undefined);
      return config.apiFunus.workOrder.findWorkOrdersDetail({
        idRecord: Number(id),
      });
    }

    if (isTvView === true && !!ip) {
      return config.apiFunus.workOrder.findPublicWorkOrdersDetail({
        center: Centers[id as CenterType],
        ip: `${ip}`,
        type,
      });
    }

    return Promise.resolve({
      data: {
        completed: [],
        inProgress: [],
        pending: [],
      },
    });
  }, [isTvView, id, ip, type]);

  const initialRequest = useCallback(() => {
    methodFindWorkOrders()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        if (orderId) {
          const allWorkOrders: Workorder[] = [
            ...response?.data?.pending || [],
            ...response?.data?.inProgress || [],
            ...response?.data?.completed || [],
          ];

          const selectedWorkorder = allWorkOrders
            .find((workOrder) => workOrder.id.toString() === orderId);

          if (selectedWorkorder?.status === StatusCodes.PENDING) {
            setButtonsState(1);
          } else if (selectedWorkorder?.status === StatusCodes.IN_PROGRESS) {
            setButtonsState(2);
          } else if (selectedWorkorder?.status === StatusCodes.COMPLETED) {
            setButtonsState(3);
          }
        }

        setWrongCenter(false);
        if (id && !Centers[id as CenterType]) {
          const rcd = response?.data?.simpleRecordDto;
          setRecord({
            deceasedData: rcd?.deceasedData,
            erpId: rcd?.erpId,
            id: Number(id),
          });
        }

        setTvAllowed(true);

        if (isTvView) {
          setItems({
            inProgress: sortWorkorders(response.data.inProgress),
            pending: sortWorkorders(response.data.pending),
          });
        } else {
          setItems({
            completed: sortWorkorders(response.data.completed),
            inProgress: sortWorkorders(response.data.inProgress),
            pending: sortWorkorders(response.data.pending),
          });
        }
        if (isTvView) {
          setTimeout(() => {
            setIsLoading(false);
          }, 5000);
        } else {
          setIsLoading(false);
        }
        return response;
      })
      .catch((error) => {
        if (error instanceof TelevisionNotExistsError) {
          setWrongCenter(false);
          setTvAllowed(false);
        } else if (!Centers[id as CenterType] || !WorkshopTypes[type]) {
          setWrongCenter(true);
          setTvAllowed(true);
        }
        setIsLoading(false);
      });
  }, [id, isTvView, methodFindWorkOrders, type, orderId]);

  useEffect(() => {
    const hasPermissions = !!user?.role?.permissions?.map(
      (permission: Permission) => permission.name,
    ).reduce((prev, curr, index, permissionNames) => prev
      || (permissionNames[index] === SimpleUserRequestPermissionNames.WORK_ORDER_WORKSHOP_ADD
      || permissionNames[index] === SimpleUserRequestPermissionNames.WORK_ORDER_CEMETERY_ADD),
    false);

    setShowFilter(hasPermissions && !lgOrSmaller);
  }, [user, lgOrSmaller]);

  useEffect(() => {
    initialRequest();
  }, [initialRequest]);

  useWorkshopRefresh(initialRequest, id, ip, user, type);

  const WorkorderTypeFilter = useCallback((item: Workorder) => {
    if (!currentFilters.type) return true;
    return item.type === currentFilters.type.code;
  }, [currentFilters]);

  const WorkOrderIsAssignedFilter = useCallback((item: Workorder) => {
    if (currentFilters.isAssigned === tristateEquivalence.all) return true;
    if (currentFilters.isAssigned) {
      return item.assignedUsers.length > 0;
    }
    return item.assignedUsers.length === 0;
  }, [currentFilters]);

  const WorkOrderDueDateFilter = useCallback((item: Workorder) => {
    if (currentFilters.serviceDate === undefined) return true;

    let dateValue;
    if (item.status === StatusCodes.PENDING) {
      dateValue = item.createdDate;
    } else if (item.status === StatusCodes.IN_PROGRESS) {
      dateValue = item.startDate;
    } else if (item.status === StatusCodes.COMPLETED && item.finishDate) {
      dateValue = item.finishDate;
    }

    return formatDate(currentFilters?.serviceDate || '') === formatDate(dateValue || '');
  }, [currentFilters]);

  const WorkOrderErpIdFilter = useCallback((item: Workorder) => {
    if (!currentFilters.erpId) return true;
    return parseInt(currentFilters.erpId, 10) === item.erpId;
  }, [currentFilters]);

  const WorkOrderDeceasedNameFilter = (item: Workorder) => {
    if (!currentFilters.deceasedName) return true;
    return `${item?.deceasedName || ''}`
      .toLowerCase()
      .includes(currentFilters?.deceasedName?.toLowerCase());
  };

  const WorkOrderDeceasedSurnameFilter = (item: Workorder) => {
    if (!currentFilters.deceasedSurname) return true;
    return `${item?.deceasedFirstSurname || ''}${item?.deceasedSecondSurname || ''}`
      .toLowerCase()
      .includes(currentFilters?.deceasedSurname?.toLowerCase());
  };

  const WorkorderDestinationCenterFilter = useCallback((item: Workorder) => {
    if (!currentFilters.destinationCenter) return true;
    const selectedCenterChildren = hierarchy
      .find((
        hierarchyItem,
      ) => hierarchyItem.code === currentFilters?.destinationCenter?.code)?.children;
    const isSelectedWorkorderDestinationCenterOrChildren = item.destinationCenter
      && (item.destinationCenter === currentFilters.destinationCenter.code
      || selectedCenterChildren?.includes(item.destinationCenter));

    return isSelectedWorkorderDestinationCenterOrChildren;
  }, [currentFilters.destinationCenter, hierarchy]);

  const renderWorkOrderGroup = useCallback(
    (workOrderId: number, code: ListStatusCode) => {
      const orderByStatus: Workorder[] = items?.[code] || [];
      const list = !orderId
        ? orderByStatus
          .filter(
            (order) => priorityState.value === 'ALL' || order.priority === priorityState.value,
          )
          .filter(WorkorderDestinationCenterFilter)
          .filter(WorkorderTypeFilter)
          .filter(WorkOrderIsAssignedFilter)
          .filter(WorkOrderDueDateFilter)
          .filter(WorkOrderErpIdFilter)
          .filter(WorkOrderDeceasedNameFilter)
          .filter(WorkOrderDeceasedSurnameFilter)
        : orderByStatus
          .filter((i) => i.id.toString() === orderId);

      return (
        <WorkOrderGroup
          key={workOrderId}
          code={code}
          id={workOrderId}
          initialRequest={initialRequest}
          list={list}
          priorityState={priorityState}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      WorkOrderDeceasedNameFilter,
      WorkOrderDeceasedSurnameFilter,
      WorkOrderDueDateFilter,
      WorkOrderErpIdFilter,
      WorkOrderIsAssignedFilter,
      WorkorderDestinationCenterFilter,
      WorkorderTypeFilter,
      items,
      orderId,
      priorityState,
    ],
  );

  const onSearch = (filters: WorkshopFilter) => {
    setCurrentFilters(filters);
  };
  return (
    <Fragment>
      {record && <RecordTopInfo record={record} />}
      <div className={classNames({ fullpage: !user })} id="workshop-page">
        {!user && id && type && (
          <TopInfo className="socket-topbar">
            <Button color="transparent" to={config.url.landing}>
              <img alt="Funus" src={logo} />
            </Button>
            <div>
              {t('user.workplace')}
              :
              <span>{id}</span>
            </div>
          </TopInfo>
        )}
        {lgOrSmaller && !wrongCenter && (
          <SelectOrderMobile
            buttonsState={{ setState: setButtonsState, state: buttonsState }}
            options={priorityOptions}
            priorityState={{ setState: setPriorityState, state: priorityState }}
          />
        )}
        {showFilter && (
        <WorkOrderFilter
          onCreateManualOT={() => initialRequest()}
          onSearch={onSearch}
        />
        )}
        <div
          className={classNames('group-cards',
            {
              mobile: lgOrSmaller,
              record,
              'show-filter': showFilter,
              'tv-view': isTvView,
            })}
        >
          {isLoading && <Loader className={classNames({ 'loader-tv': isTvView })} />}
          {!isLoading && ip && !tvAllowed && (
            <div className="not-allowed">
              <Message type="error">
                <p>{t('ip.notAllowed')}</p>
                <strong>{ip}</strong>
              </Message>
            </div>
          )}
          {!isLoading && wrongCenter && (
            <div className="not-allowed">
              <Message type="error">
                <p>{t('ip.wrongCenter')}</p>
              </Message>
            </div>
          )}
          {!isLoading && tvAllowed && !wrongCenter && (
            <Fragment>
              {lgOrSmaller
                ? renderWorkOrderGroup(
                  buttonsState,
                  kindListOrders[buttonsState - 1].code,
                )
                : kindListOrders.map(
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  ({ id: workOrderId, code }: any) => renderWorkOrderGroup(workOrderId, code),
                )}
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default WorkshopPage;
