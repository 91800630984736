const record = {
  abortion: {
    declarantIsMother: 'El declarant és la mare del fetus',
    father: 'del pare',
    gestationWeeks: 'Setmanes de gestació',
  },
  addAppointmentKo: 'S\'ha produït un error en crear la citació',
  adviserAssigned: 'La llista d\'assessors ha estat actualitzada amb èxit!',
  advisers: 'Assessor',
  ageConfidential: 'Confidencialitat d\'edat',
  amputation: {
    member: 'Membre amputat',
  },
  appointment: 'Compromís família',
  ashesDelivery: 'Entrega de cendres',
  ashesDeliveryKo: 'S\'ha produït un error en lliurar les cendres',
  ashesDeliveryOk: 'Les cendres s\'han lliurat correctament',
  assignAdviser: 'Assignar assessor',
  assignAdviserError: 'Error a l\'hora d\'intentar actualitzar la llista d\'assessors assignats',
  assignedRecords: 'Expedients assignats',
  billingData: {
    sendByEmail: 'Enviament de factura per email',
  },
  client: 'Client',
  clients: {
    paymentTerm: 'Termini pagament',
    primaryLanguage: 'Idioma principal',
    secondaryLanguage: 'Idioma secundari',
  },
  close: 'Finalitzar expedient',
  closedKo: 'S\'ha produït un error en finalitzar l\'expedient. Torneu-ho a provar.',
  closedOk: 'L\'expedient s\'ha finalitzat correctament.',
  closeText: 'Finalitzarà l\'expedient',
  coffinSizes: {
    SIZE_060: '60',
    SIZE_080: '80',
    SIZE_100: '100',
    SIZE_140: '140',
    SIZE_190: '190',
    SIZE_205: '205',
    SIZE_205_SUPER: '205 SUPER',
    SIZE_205_SUPER_EXTRA: '205 SUPER EXTRA',
  },
  collectionComment: 'Comentari recollida',
  confirmDelete: 'Esteu segur que voleu eliminar l\'expedient?',
  createdBy: 'Creat per',
  createPickup: 'Crear ordre de recollida',
  createReception: 'Crear ordre de recepció',
  createWorkOrder: 'Crear ordre de treball',
  creationDate: 'Data de creació',
  deathData: {
    approximatedDate: 'Data aproximada',
    atHome: 'Domicili',
    deathCause: 'Causa de la defunció',
    deathDate: 'Data de defunció',
    deathPlace: 'Lloc de defunció',
    deathTime: 'Hora de la defunció',
    deathType: 'Tipus de defunció',
    destinationCenter: 'Centro destí',
    diseases: 'Grup causa',
    doctor: 'Metge',
    errands: 'Diligències',
    home: 'Domicili',
    medicalCertificate: 'Es disposa del certificat mèdic de defunció',
    pickup: 'Recollida',
    pickupAddress: 'Lloc de recollida',
    pickupType: 'Tipus de recollida',
    remarks: 'Observacions de la defunció',
    trialCourt: 'Jutjat d\'instrucció',
    type: {
      judicial: 'Judicial',
      normal: 'Natural',
    },
  },
  deceased: 'Titular de l\'expedient',
  deceasedData: {
    birth: 'Naixement',
    childrenNames: 'Observacions fills',
    fatherName: 'Nom pare',
    holder: 'Titular de l\'expedient',
    home: 'Residència',
    maritalStatus: 'Estat civil',
    motherName: 'Nom mare',
    nationality: 'Nacionalitat',
    nickname: 'Sobrenom',
    partnerName: 'Observacions estat civil',
  },
  declarant: 'Declarant',
  declarantData: 'El sol·licitant és el declarant',
  delete: 'Eliminar expedient',
  deliveryObjects: 'Lliurament d\'objectes personals',
  deliveryObjectsAdd: 'Afegir QR',
  deliveryObjectsOverride: 'Ja hi ha objectes personals registrats, què voleu fer?',
  deliveryObjectsRepeated: 'El QR ja està afegit',
  deliveryObjectsReplace: 'Substituir',
  detail: 'Detall d\'expedient',
  document: {
    removeKo: 'S\'ha produït un error a l\'hora d\'eliminar el fitxer.',
    removeOk: 'El fitxer s\'ha eliminat correctament',
    sure: 'Eliminarà el fitxer',
    uploadKo: 'S\'ha produït un error a l\'pujar el fitxer.',
    uploadOk: 'El fitxer s\'ha pujat correctament',
  },
  draft: 'Esborrany',
  edit: 'Editar expedient',
  editKo: 'S\'ha produït un error a l\'hora d\'actualitzar l\'expedient',
  editOk: 'L\'expedient s\'ha actualitzat satisfactòriament',
  erpId: 'Expedient',
  firstNegotiator: 'Assesor',
  info: 'Dades de lexpedient',
  informationFilterType: {
    APPOINTMENT: 'Compromís família',
    CEREMONY: 'Cerimònia',
    COLLECTION: 'Data recollida',
    CREMATION: 'Incineració',
    INTERMENT: 'Inhumació',
    VIGIL: 'Vetlla',
  },
  insuranceData: {
    agent: 'Agent',
    amount: 'Cobertures',
    companyDelegation: 'Delegació de la companyia',
    companyName: 'Nom de la companyia',
    hasPolicy: 'Es disposa de pòlissa d\'assegurança',
    idPaymentLocation: 'Localització cobrament',
    maxCoverage: 'Import màxim cobertura',
    policyNumber: 'Número de pòlissa',
    renounce: 'Renúncia de capital de pòlissa',
    sinister: 'Número de sinistre',
  },
  last: 'Darrers expedients',
  manage: 'Administrar',
  myRecords: 'Els meus expedients',
  new: 'Nou expedient',
  noAppointment: 'Sense cita',
  notAssignedRecords: 'Expedients sense assignar',
  number: 'Nº expedient',
  objectsDelivered: '¡El lliurament d\'objectes personals ha estat guardat amb èxit!',
  pickupDate: 'Data Recollida',
  preparationStatus: 'Estat Preparació',
  preparationStatusOptions: {
    FINISHED: 'Instal·lat',
    ON_GOING: 'Condicionant',
  },
  processing: 'Tramitació',
  publishCeremony: 'Cerimònia visible a web',
  publishVigil: 'Vetlla visible a web',
  reception: {
    entryDate: 'Data arribada',
    entryTime: 'Hora arribada',
    funeraryOrigin: 'Funerària de procedència',
    isReception: 'Es tracta d’una recepció',
    receptionType: 'Tipus de recepció',
  },
  receptionType: {
    withCoffin: 'Amb taüt',
    withoutCoffin: 'Sense taüt',
  },
  receptionTypeInfo: {
    withCoffin: 'Encaixat',
    withoutCoffin: 'Protocol camilla',
  },
  recordDeleteKo: 'Error en eliminar l\'expedient',
  recordDeleteKoConflict: 'Expedient no compleix validació',
  recordDeleteKoEkon: 'Fallada invocació EKON',
  requestError: 'Error a l\'hora de sol·licitar les dades de la taula',
  restRecords: 'Resta d\'expedients assignats',
  saveKo: 'S\'ha produït un error a l\'hora de crear l\'expedient',
  saveOk: 'L\'expedient s\'ha creat satisfactòriament',
  search: 'Cercar expedients',
  secondNegotiator: 'Ceremonial',
  selectHeadlineAdviser: 'Assignar assessor',
  selectSecondaryAdviser: 'Assignar cerimonial',
  sendByEmail: 'Enviament de factura per email',
  state: 'Estat',
  states: {
    FINISHED: 'Finalitzat',
    INVOICED: 'Facturat',
    INVOICED_PENDING: 'Pendent de facturació',
    OPENED: 'Obert',
  },
  steps: {
    abortion: 'Avortament',
    amputation: 'Amputació',
    billing: 'Facturació',
    death: 'Defunció',
    deceased: 'Titular de l\'expedient',
    declarant: 'Declarant',
    documents: 'Documents',
    general: 'Dades generals',
    insurance: 'Assegurança',
    other: 'Altres',
    pickup: 'Recollida',
    reception: 'Recepció',
  },
  title: 'Expedients',
  type: 'Tipus',
  validate: 'Validar',
  whatsapp: {
    language: 'Idioma del missatge',
    lastUpdate: 'Últim enviament realitzat per {{user}} el {{date}} al número {{number}}',
    number: 'Número telèfon',
    numberUpdated: 'Número enviament de Whatsapp actualitzat',
    sendSuccess: 'Enviament del missatge processat',
    title: 'Enviament whatsapp',
    whatsappConfirmation: 'S\'enviarà un missatge amb les dades de la defunció al telèfon: {{number}}.',
    whatsappConfirmationCeremony: 'Cerimònia: El {{fromDate}} a les {{fromTime}}h a {{location}}',
    whatsappConfirmationNoCeremony: 'Cerimònia: sense cerimònia',
    whatsappConfirmationNoVigil: 'Vetlla: sense vetlla',
    whatsappConfirmationVigil: 'Vetlla: del {{fromDate}} a les {{fromTime}}h a {{toDate}} a les {{toTime}}h a {{location}}',
  },
  withAppointment: 'Amb cita',
};
export default record;
