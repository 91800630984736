import { FormikProps } from 'formik';
import React, { Fragment } from 'react';

import config from 'config';

import Button from 'components/base/Button';

import { i18n } from 'i18n';
import { UpdateUserProfile } from 'models/User';
import NotificationsToogle from 'modules/push-notifications/components/notifications-toogle/indext';

import Upload from '../Upload';

import './index.scss';

export const getUserProfileForm = (
  { isSubmitting, setFieldValue, values }: FormikProps<UpdateUserProfile>,
  disabled?: boolean,
): JSX.Element => (
  <Fragment>
    <fieldset>
      <legend>{i18n.t('user.profile.changeImage')}</legend>
      <Upload
        disabled={disabled || isSubmitting}
        name="image"
        placeholder={i18n.t('user.userImage')}
        value={values.image}
        onChange={(val) => setFieldValue('image', val)}
      />
    </fieldset>
    <fieldset className="change-password">
      <legend>{i18n.t('user.profile.changePass')}</legend>
      <Button
        text={i18n.t('user.profile.changePass')}
        to={`${config.url.userProfile}${config.url.changePassword}`}
      />
    </fieldset>
    <fieldset className="push-notifications">
      <legend>{i18n.t('user.profile.allowNotifications')}</legend>
      <NotificationsToogle />
    </fieldset>
  </Fragment>
);

export const profileFormInitialValues = {
  image: '',
};
