const settings = {
  blocks: {
    WHATSAPP_SETTINGS: 'WhatsApp',
  },
  loadError: 'Error en obtenir la configuració',
  saveError: 'Error al guardar la configuración',
  saveSuccess: 'Configuración guardada',
  whatsapp: {
    authToken: 'Token de autenticación',
    destinationNumberPhone: 'Teléfono de destino',
    language: 'Idioma',
    sourceNumberPhone: 'Número de teléfono de origen',
    templateName: 'Nombre de la plantilla',
    test: 'Probar configuración',
  },
};

export default settings;
