import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import CustomIcon from 'components/base/CustomIcon';
import InputWithContent, {
  ContentProps,
} from 'components/base/InputWithContent';
import QRScan from 'components/base/QRScan';

import { OtherTypes } from '../CustomIcon/types';

import './index.scss';
import { InputQRScanProps } from './InputQRScanProps';

const InputQRScan: FC<InputQRScanProps> = ({
  data,
  disabled,
  error,
  placeholder,
  setData,
  name = 'inputQR',
}) => {
  const { t } = useTranslation();

  const getContent = ({ hide, expanded }: ContentProps) => {
    if (expanded) {
      return <QRScan setData={setData} onHide={hide} />;
    }
    return undefined;
  };

  return (
    <InputWithContent
      className="input-qr"
      content={getContent}
      disabled={disabled}
      error={error}
      icon={<CustomIcon className="qr-icon" icon={OtherTypes.QR} />}
      name={name}
      placeholder={placeholder || t('common.readQR')}
      value={data}
    />
  );
};

export default InputQRScan;
