import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/base/Modal';

export const PersonaItemsOverrideModal: FC<{
  show: boolean;
  onCancel: () => void;
  onAccept: (value: boolean) => void;
}> = ({
  show, onCancel, onAccept,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      buttons={[
        {
          color: 'secondary',
          id: 'cancel',
          onClick: () => onCancel(),
          text: t('common.cancel'),
        },
        {
          id: 'yes',
          onClick: () => onAccept(true),
          text: t('record.deliveryObjectsReplace'),
        },
        {
          id: 'no',
          onClick: () => onAccept(false),
          selected: true,
          text: t('record.deliveryObjectsAdd'),
        },
      ]}
      className="personal-items-override -modal"
      closeOnFocusLost={false}
      show={show}
      showCancel={false}
      showXButton={false}
      title={t('record.deliveryObjects')}
      onHide={onCancel}
    >
      <div>{t('record.deliveryObjectsOverride')}</div>
    </Modal>
  );
};
