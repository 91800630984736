import classNames from 'classnames';
import { FormikProps } from 'formik';
import React, { useMemo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import config from 'config';

import { formatDate } from 'utils/dateManager';

import FilteredPage from 'components/base/FilteredPage';
import SideFilterField from 'components/base/SideFilters/SideFilterField';
import { TableColumn } from 'components/base/Table/types';

import { i18n } from 'i18n';
import { IMasterDataDto } from 'models/MasterData';
import QrDetail, { SearchQrProps } from 'models/Qr';
import { QRType } from 'models/QRType';
import { RecordStateEnum } from 'models/Record';
import { RootState } from 'store';

const fields = (
  formikProps: FormikProps<SearchQrProps>,
  recordTypes: IMasterDataDto[],
): ReactElement[] => [
  <SideFilterField
    key="erpId"
    filterType="input"
    formikProps={formikProps}
    name="erpId"
    placeholder={`${i18n.t('record.number')}`}
  />,
  <SideFilterField
    key="death.deathDate"
    filterType="date"
    formikProps={formikProps}
    name="death.deathDate"
    placeholder={`${i18n.t('common.date')}`}
  />,
  <SideFilterField
    key="deceased.name"
    filterType="input"
    formikProps={formikProps}
    name="deceased.name"
    placeholder={`${i18n.t('common.name')}`}
  />,
  <SideFilterField
    key="deceased.firstSurname"
    filterType="input"
    formikProps={formikProps}
    name="deceased.firstSurname"
    placeholder={`${i18n.t('common.firstSurname')}`}
  />,
  <SideFilterField
    key="deceased.secondSurname"
    filterType="input"
    formikProps={formikProps}
    name="deceased.secondSurname"
    placeholder={`${i18n.t('common.secondSurname')}`}
  />,
  <SideFilterField<SearchQrProps, IMasterDataDto>
    key="type"
    filterType="select"
    formikProps={formikProps}
    getLabel={({ description }) => description}
    getValue={({ code }) => code}
    name="type"
    options={recordTypes}
    placeholder={`${i18n.t('record.type')}`}
  />,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <SideFilterField<any, RecordStateEnum>
    key="state"
    filterType="select"
    formikProps={formikProps}
    getLabel={(label) => i18n.t(`record.states.${label}`)}
    getValue={(value) => value}
    name="state"
    options={Object.values(RecordStateEnum)}
    placeholder={`${i18n.t('record.state')}`}
    clearable
  />,
  <SideFilterField
    key="comment"
    filterType="input"
    formikProps={formikProps}
    name="comment"
    placeholder={`${i18n.t('common.observations')}`}
  />,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const apiCall = (params: any) => config.apiFunus.qr.getQrs({
  ...params,
  filter: [
    {
      ...params.filter[0],
      type: (params.filter[0].type as IMasterDataDto)?.code || undefined,
    },
  ],
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const QrTable = (): any => {
  const { t } = useTranslation();
  const { recordTypes } = useSelector((state: RootState) => state.masterData);

  const columns = useMemo(
    (): TableColumn<QrDetail>[] => [
      { accessor: 'id', Header: 'Id', hidden: true },
      {
        accessor: 'erpId',
        Header: `${t('record.number')}`,
        sortable: true,
      },
      {
        accessor: 'death.deathDate',
        Cell: ({
          row: {
            original: { deathDate },
          },
        }) => {
          if (deathDate) {
            return formatDate(deathDate);
          }
          return '';
        },
        Header: `${t('record.deathData.deathDate')}`,
        sortable: true,
        width: '10%',
      },
      {
        accessor: 'deceased.name',
        Cell: ({
          row: {
            original: { name, firstSurname, secondSurname },
          },
        }) => classNames(name, firstSurname, secondSurname) || '',
        className: 'primary-dark-color',
        Header: `${t('common.name')}`,
        sortable: true,
        width: '40%',
      },
      {
        accessor: 'qr',
        Header: `${t('qr.qr')}`,
        sortable: false,
      },
      {
        accessor: 'qrs',
        Cell: ({
          row: {
            original: { qrs },
          },
        }) => qrs
          .filter((qr) => qr.type === QRType.PERSONAL_ITEM)
          .map((qr) => qr.content)
          .join(', '),
        Header: `${t('qr.personalItemsQr')}`,
        sortable: false,
      },
      {
        accessor: 'coffinQr',
        Header: `${t('qr.coffinQr')}`,
        sortable: false,
      },
      {
        accessor: 'urnQr',
        Header: `${t('qr.urnQr')}`,
        sortable: false,
      },
    ],
    [t],
  );

  return (
    <FilteredPage<SearchQrProps, QrDetail>
      apiCall={(params) => apiCall(params)}
      columns={columns}
      fields={(props) => fields(props, recordTypes)}
      initialValues={{}}
      text={{
        search: t('qr.search'),
        title: t('qr.title'),
      }}
    />
  );
};

export default QrTable;
