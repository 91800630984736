import classNames from 'classnames';
import React, {
  FC, ReactElement, cloneElement, useEffect, useRef,
} from 'react';
import { ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import './index.scss';

export type ButtonProps = {
  id?: string;
  className?: string;
  color?: 'primary' | 'secondary' | 'attention' | 'special' | 'transparent' | 'accent';
  disabled?: boolean;
  external?: boolean;
  leftAddon?: ReactElement;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e?: any) => void;
  onMouseOver?: () => void;
  rightAddon?: ReactElement;
  testid?: string;
  tabIndex?: number;
  text?: string;
  to?: string;
  type?: 'submit' | 'reset' | 'button';
  role?: string;
  selected?: boolean;
};

const Button: FC<ButtonProps> = ({
  id,
  className,
  children,
  color,
  disabled,
  external,
  leftAddon,
  onClick,
  onMouseOver,
  rightAddon,
  tabIndex,
  text,
  testid,
  to,
  type,
  role = 'button',
  selected,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const btnRef = useRef<any>();

  useEffect(
    () => {
      if (selected) {
        btnRef?.current?.focus?.();
      }
    },
    [selected],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const checkOnClick = (e: any) => {
    (e.target as HTMLElement)?.blur();
    if (!disabled && onClick) {
      onClick(e);
    }
  };

  if (to && external) {
    return (
      <a
        className={classNames('funus-button', color, className, { disabled })}
        data-testid={testid}
        href={to}
        id={id}
        rel="noreferrer"
        tabIndex={tabIndex}
        target="_blank"
        onClick={checkOnClick}
        onFocus={onMouseOver}
        onMouseOver={onMouseOver}
      >
        {leftAddon
          && cloneElement(leftAddon, {
            className: classNames('button-prefix', leftAddon.props.className),
          })}
        <div>
          {text}
          {color === 'special' && <ChevronRight />}
        </div>
        {children}
        {rightAddon
          && cloneElement(rightAddon, {
            className: classNames('button-suffix', rightAddon.props.className),
          })}
      </a>
    );
  }

  if (to) {
    return (
      <Link
        className={classNames('funus-button', color, className, { disabled })}
        data-testid={testid}
        id={id}
        tabIndex={tabIndex}
        to={to}
        onClick={checkOnClick}
        onFocus={onMouseOver}
        onMouseOver={onMouseOver}
      >
        {leftAddon
          && cloneElement(leftAddon, {
            className: classNames('button-prefix', leftAddon.props.className),
          })}
        <div>
          {text}
          {color === 'special' && <ChevronRight />}
        </div>
        {children}
        {rightAddon
          && cloneElement(rightAddon, {
            className: classNames('button-suffix', rightAddon.props.className),
          })}
      </Link>
    );
  }

  return (
    <button
      ref={btnRef}
      aria-selected={selected}
      className={classNames('funus-button', color, className, { disabled })}
      data-testid={testid}
      disabled={disabled}
      id={id}
      role={role}
      tabIndex={tabIndex}
      // TODO: Check how to remove eslint-disable
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={checkOnClick}
      onFocus={onMouseOver}
      onMouseOver={onMouseOver}
    >
      {leftAddon
        && cloneElement(leftAddon, {
          className: classNames('button-prefix', leftAddon.props.className),
        })}
      {text && (
        <div className="button-text">
          {text}
          {color === 'special' && <ChevronRight />}
        </div>
      )}
      {children}
      {rightAddon
        && cloneElement(rightAddon, {
          className: classNames('button-suffix', rightAddon.props.className),
        })}
    </button>
  );
};

Button.defaultProps = {
  className: undefined,
  color: 'primary',
  disabled: false,
  leftAddon: undefined,
  onClick: undefined,
  rightAddon: undefined,
  tabIndex: undefined,
  testid: undefined,
  text: undefined,
  to: undefined,
  type: 'button',
};

export default Button;
