import { getIn } from 'formik';
import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { isBooleanAndFalse, isBooleanAndTrue } from 'utils/helpers';

import Button from 'components/base/Button';
import CustomIcon from 'components/base/CustomIcon';
import { FeatherIconTypes, OtherTypes } from 'components/base/CustomIcon/types';
import Input from 'components/base/Input';
import LanguageSelector from 'components/base/LanguageSelector';

import { useWhatsapp } from 'hooks/useWhatsapp';

import { ISettingsWhatsappForm } from './types';

export const SettingsWhatsappForm: FC<ISettingsWhatsappForm> = ({
  value, handleBlur, onChange, errors,
}) => {
  const { t } = useTranslation();
  const {
    testSettings, testStatus: testSuccess, languages, loadLanguages,
  } = useWhatsapp();

  useEffect(
    () => {
      loadLanguages();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const languagesOptions = useMemo(
    () => languages.map((locale) => ({
      label: t(`common.locales.${locale}`),
      value: locale,
    })),
    [languages, t],
  );

  const testStatusIcon = useMemo(() => {
    if (isBooleanAndTrue(testSuccess)) {
      return (<CustomIcon icon={FeatherIconTypes.CHECKCIRCLE} />);
    } if (isBooleanAndFalse(testSuccess)) {
      return (<CustomIcon icon={OtherTypes.ERROR} />);
    }
    return (<React.Fragment />);
  }, [testSuccess]);

  const canTest = !!value.authToken
  && !!value.sourceNumberPhone
  && !!value.templateName && !!value.destinationNumberPhone
&& !!value.language;

  const [bearer, token] = value.authToken.split(' ');

  return (
    <div className="form-generator">
      <Input
        error={getIn(errors, 'whatsappSettingsDto.authToken')}
        name="whatsapp.authToken"
        placeholder={t('settings.whatsapp.authToken')}
        value={token || ''}
        onBlur={handleBlur}
        onChange={(val: string) => {
          onChange({
            ...value,
            authToken: `${bearer || 'Bearer'} ${val}`,
          });
        }}
      />
      <Input
        error={getIn(errors, 'whatsappSettingsDto.sourceNumberPhone')}
        name="whatsapp.sourceNumberPhone"
        placeholder={t('settings.whatsapp.sourceNumberPhone')}
        value={value.sourceNumberPhone}
        onBlur={handleBlur}
        onChange={(val: string) => {
          onChange({
            ...value,
            sourceNumberPhone: val,
          });
        }}
      />
      <Input
        error={getIn(errors, 'whatsappSettingsDto.templateName')}
        name="whatsapp.templateName"
        placeholder={t('settings.whatsapp.templateName')}
        value={value.templateName}
        onBlur={handleBlur}
        onChange={(val: string) => {
          onChange({
            ...value,
            templateName: val,
          });
        }}
      />
      <Input
        error={getIn(errors, 'whatsappSettingsDto.destinationNumberPhone')}
        name="whatsapp.destinationNumberPhone"
        placeholder={t('settings.whatsapp.destinationNumberPhone')}
        value={value.destinationNumberPhone}
        onBlur={handleBlur}
        onChange={(val: string) => {
          onChange({
            ...value,
            destinationNumberPhone: val,
          });
        }}
      />
      <LanguageSelector
        className="f-language-selector"
        error={getIn(errors, 'whatsappSettingsDto.language')}
        name="whatsapp.language"
        options={languagesOptions}
        placeholder={t('settings.whatsapp.language')}
        value={value.language}
        onBlur={handleBlur}
        onChange={(val) => {
          onChange({
            ...value,
            language: val?.value,
          });
        }}
      />
      <div className="buttons-panel">
        <Button
          color="secondary"
          disabled={!canTest}
          text={t('settings.whatsapp.test')}
          onClick={() => {
            testSettings(value);
          }}
        />
        {testStatusIcon}
      </div>
    </div>
  );
};
