import { MasterServiceDtoType } from 'config/apiFunus/generated/data-contracts';
import OrderType from 'models/OrderType';

export enum OtherTypes {
  ACCEPT_FLOWERS_REQUEST = 'ACCEPT_FLOWERS_REQUEST',
  ADD = 'ADD',
  ADD_ARTICLE_DISAGREEMENT = 'ADD_ARTICLE_DISAGREEMENT',
  AFFECTED_QUESTIONS_ALERT = 'AFFECTED_QUESTIONS_ALERT',
  ARTICLE_DRAFT_EDIT = 'ARTICLE_DRAFT_EDIT',
  ASSIGN_RECORD = 'ASSIGN_RECORD',
  BUDGET = 'BUDGET',
  BUDGET_ACCEPT = 'BUDGET_ACCEPT',
  BUDGET_BACKWARD = 'BUDGET_BACKWARD',
  BUDGET_CONFIG = 'BUDGET_CONFIG',
  BUDGET_EDIT_QUESTION = 'BUDGET_EDIT_QUESTION',
  CANCEL_FLOWERS = 'cancel-flowers',
  DELETE = 'DELETE',
  DUPLICATE = 'DUPLICATE',
  EMAIL = 'EMAIL',
  FLOWERS = 'flowers',
  INVOICE = 'INVOICE',
  MOVE_BACK = 'MOVE_BACK',
  MOVE_END = 'MOVE_END',
  MOVE_NEXT = 'MOVE_NEXT',
  NO_ELEMENTS = 'no-elements',
  PENDANT = 'pendant',
  PHOTO = 'PHOTO',
  PRINT = 'PRINT',
  PRINT_ARTICLE_COMERCIAL = 'PRINT_ARTICLE_COMERCIAL',
  QR = 'QR',
  RELIQUARY = 'reliquary',
  RESTORE = 'RESTORE',
  SAVE = 'SAVE',
  SIGN = 'SIGN',
  ERROR = 'ERROR'
}

export enum FeatherIconTypes {
  CHECKCIRCLE = 'CHECKCIRCLE',
  CHEVRONLEFT = 'CHEVRONLEFT',
  CHEVRONRIGHT = 'CHEVRONRIGHT',
  CLOCK = 'CLOCK',
  EDIT = 'EDIT',
  EYE = 'EYE',
  FILEPLUS = 'FILEPLUS',
  PACKAGE = 'PACKAGE',
  SEND = 'SEND',
  SETTINGS = 'SETTINGS',
  TRASH = 'TRASH',
  TRASH2 = 'TRASH2',
  TRELLO = 'TRELLO',
  USERPLUS = 'USERPLUS',
}

export type CustomIconProps = {
  className?: string;
  icon: MasterServiceDtoType | OrderType | OtherTypes | FeatherIconTypes;
};
