import { FormikProps } from 'formik';
import React, {
  Fragment, FC, ReactElement, useState, useMemo,
} from 'react';
import { Copy } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import config from 'config';

import { checkHaveThisPermissions, checkPermission } from 'utils/permissions';
import { showErrorToast, showSuccessToast } from 'utils/toasts';

import { addRoles } from 'actions/roles';

import { ButtonProps } from 'components/base/Button';
import CustomIcon from 'components/base/CustomIcon';
import { FeatherIconTypes } from 'components/base/CustomIcon/types';
import FilteredPage from 'components/base/FilteredPage';
import Modal from 'components/base/Modal';
import SideFilterField from 'components/base/SideFilters/SideFilterField';
import { TableActionsProps } from 'components/base/Table';
import { TableColumn } from 'components/base/Table/types';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { useProvidedAuth } from 'hooks/useProvidedAuth';
import { i18n } from 'i18n';
import { SearchUserProps } from 'models/User';
import UserRole from 'models/UserRole';
import { RootState } from 'store';

type RoleExtended = UserRole & {
  translation: string;
};

type RoleToDeleteType = {
  row: RoleExtended;
  index: number;
  reFetch: () => void;
};

const fields = (formikProps: FormikProps<SearchUserProps>): ReactElement[] => [
  <SideFilterField
    key="name"
    filterType="input"
    formikProps={formikProps}
    name="name"
    placeholder={`${i18n.t('common.name')}`}
  />,
  <SideFilterField
    key="remarks"
    filterType="input"
    formikProps={formikProps}
    name="remarks"
    placeholder={`${i18n.t('common.observations')}`}
  />,
];

const RolesTable: FC = () => {
  const [roleToDelete, setRoleToDelete] = useState<RoleToDeleteType>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { user } = useProvidedAuth();
  const { roles } = useSelector((state: RootState) => state.roles);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const columns = useMemo(
    (): TableColumn<RoleExtended>[] => [
      { accessor: 'id', Header: 'Id', hidden: true },
      {
        accessor: 'name',
        Cell: ({ cell: { value: val } }) => t(`role.${val}`, val),
        Header: `${t('common.name')}`,
        sortable: true,
      },
      {
        accessor: 'createdBy',
        Header: `${t('user.role.createdBy')}`,
        sortable: true,
      },
      {
        accessor: 'description',
        Header: `${t('user.role.description')}`,
        sortable: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, roles],
  );

  const closeModalAndReset = () => {
    setShowDeleteModal(false);
    setRoleToDelete(undefined);
  };

  const preDeleteRole = (
    row: RoleExtended,
    index: number,
    reFetch: () => void,
  ) => {
    setRoleToDelete({ index, reFetch, row });
    setShowDeleteModal(true);
  };

  const deleteRole = () => {
    config.apiFunus.roles
      .deleteRole(roleToDelete?.row.id as string | number)
      .then(() => {
        roleToDelete?.reFetch();
        dispatch(addRoles());
        showSuccessToast(t('user.role.deleteOk'));
        return true;
      })
      .catch(() => {
        showErrorToast(t('user.role.deleteKo'));
      });
    closeModalAndReset();
  };

  const modalButtons: Array<ButtonProps> = [
    {
      color: 'primary',
      id: 'accept',
      onClick: deleteRole,
      text: t('common.accept'),
      type: 'button',
    },
  ];

  const getActions = (row: RoleExtended, index: number) => {
    const actions: TableActionsProps = { extra: [] };
    if (
      checkPermission(SimpleUserRequestPermissionNames.USER_READ, user?.role.permissions)
      && !checkPermission(SimpleUserRequestPermissionNames.USER_WRITE, user?.role.permissions)
    ) {
      actions.see = {
        icon: <CustomIcon icon={FeatherIconTypes.EYE} />,
        tooltipCaption: t('common.see'),
        url: `${config.url.roles}/${row.id}`,
      };
    }

    if (
      checkHaveThisPermissions(
        [SimpleUserRequestPermissionNames.USER_READ, SimpleUserRequestPermissionNames.USER_WRITE],
        user?.role.permissions,
      )
    ) {
      actions.edit = {
        icon: <CustomIcon icon={FeatherIconTypes.EDIT} />,
        tooltipCaption: t('common.edit'),
        url: `${config.url.roles}/${row.id}/edit`,
      };

      actions.clone = {
        icon: <Copy />,
        tooltipCaption: t('user.role.clone'),
        url: `${config.url.roles}/${row.id}/clone`,
      };
      actions.remove = {
        icon: <CustomIcon icon={FeatherIconTypes.TRASH} />,
        onClick: (reFetch: () => void) => preDeleteRole(row, index, reFetch),
        tooltipCaption: t('common.remove'),
      };
    }

    return actions;
  };

  return (
    <Fragment>
      <Modal
        buttons={modalButtons}
        show={showDeleteModal}
        title={t('role.delete')}
        onHide={closeModalAndReset}
      >
        {t('role.sureDelete')}
      </Modal>
      <FilteredPage<SearchUserProps, RoleExtended>
        actions={getActions}
        apiCall={config.apiFunus.roles.getPaginatedRole}
        columns={columns}
        create={{
          title: t('user.role.new'),
          url: config.url.newRole,
        }}
        fields={fields}
        initialValues={{
          name: '',
        }}
        text={{
          search: t('role.search'),
          title: t('user.role.title'),
        }}
      />
    </Fragment>
  );
};

export default RolesTable;
