const order = {
  addDeceasedQR: 'Añadir QR del difunto',
  addDeceasedQRKO: 'Error al añadir QR de difunto',
  addDeceasedQROK: ' QR de difunto actualizado con exito',
  adviserComment: 'Comentarios asesor',
  andOther: 'tareas más que no se están mostrando',
  assigned: 'Asignada',
  create: 'Crear orden manual',
  created: '¡Orden creada con éxito!',
  createError: 'No se ha podido crear la orden',
  deceasedName: 'Nombre del difunto',
  deceasedSurname: 'Apellido del difunto',
  deposit: 'Depósito',
  details: {
    cemetery: 'Cementerio',
    cemeteryDestination: 'Cementerio destino',
    cemeteryOrigin: 'Cementerio origen',
    ceremonial: 'Ceremonial',
    ceremonyLocation: 'Ubicación ceremonia',
    ceremonySchedule: 'Horario de la ceremonia',
    coldRoom: 'Cámara frigorífica',
    collection: 'Colección',
    creationHour: 'Hora de creación',
    cremationTime: 'Hora de incineración',
    deceasedLocation: 'Ubicación del fallecido',
    deliveryLocation: 'Ubicación de entrega',
    description: 'Descripción',
    design: 'Destino',
    destination: 'Destino',
    destinationCenter: 'Centro de destino',
    detail: 'Detalle',
    details: 'Detalles',
    documentationDelivered: 'Documentación entregada',
    finalDestination: 'Destino final',
    funerariComment: 'Comentario funeraria',
    location: 'Ubicación',
    mortuary: 'Funeraria',
    niche: 'Nicho',
    nicheDestination: 'Nicho destino',
    nicheOrigin: 'Nicho origen',
    noDetails: 'Se ha producido un error al cargar los datos',
    origin: 'Origen',
    outfitting: 'Equipamiento',
    sanitaryPracticeAuthorization: 'Autoritzación de práctica sanitaria',
    sanitaryPractices: 'Prácticas sanitarias',
    schedule: 'Calendario',
    target: 'Objetivo',
    transfer: 'Transferencia',
    transport: 'Transporte',
    type: 'Tipo',
    urn: 'Urn',
    vehicle: 'Vehículo',
    vigilDate: 'Fecha del velatorio',
    vigilHour: 'Hora del velatorio',
    vigilLocation: 'Lugar del velatorio',
    vigilRoom: 'Velatorio',
  },
  driver: 'Conductor',
  edit: 'Editar orden manual',
  enums: {
    info: {
      ADOL: 'Adol',
      AFEITADO: 'Afeitado',
      AVION: 'Avión',
      CAMARA: 'Cámara',
      CAMARA_FRIGORIFICA: 'Cámara frigorífica',
      CARRETERA: 'Carretera',
      CELOFANA: 'Celofana',
      CEMENTERIO: 'Cementerio',
      CENTRO: 'Centro',
      CREMATORIO: 'Crematorio',
      DOMICILIO: 'Domicilio',
      ENTREGA: 'Entrega',
      ESTANDAR: 'Estandar',
      FERETRO: 'Feretro',
      HOSPITAL: 'Hospital',
      INSTALACION: 'Instalación',
      JUDICIAL: 'Judicial',
      MAQUILLAJE: 'Maquillaje',
      MARCAPASOS: 'Marcapasos',
      OBJETOS_PERSONALES: 'Objetos personales',
      PEINADO: 'Peinado',
      PRACTICA_SANITARIA: 'Práctica sanitária',
      RESIDENCIA: 'Resicencia',
      SERRADURES_SULFAT: 'Serrín sulfato',
      TAPONAR: 'Taponar',
      VESTIMENTA: 'Vestimenta',
    },
    priority: {
      HIGH: 'Alta',
      NORMAL: 'Normal',
      URGENT: 'Urgente',
    },
  },
  finished: '¡Orden finalizada con éxito!',
  finishOrder: 'Finalizar orden',
  getError: '¡Ha habido un problema al traer los datos de la orden!',
  noOptions: 'No existen opciones',
  noOrders: 'No hay órdenes',
  notAssigned: 'No asignada',
  notFinished: 'Error al intentar finalizar la orden',
  notStarted: 'Error al intentar comenzar la orden',
  priority: 'Prioridad',
  qr: {
    coffin: {
      empty: 'El QR del ataúd no informado ',
      mismatch: 'El QR informado del ataúd no coincide con el qr de ataúd asociado al difunto',
      occupied: 'El QR informado del ataúd pertenece a otro difunto',
    },
    coffinQr: 'Escanee el QR asociado al ataud',
    deceased: {
      empty: 'El QR del difunto no informado',
      mismatch: 'El QR informado del difunto no coincide con el qr asociado al difunto',
      occupied: 'El QR informado del difunto pertenece a otro difunto',
    },
    deceasedQr: 'Escanee el QR asociado al fallecido',
    error: {
      alreadyAssigned: 'Ya está asociado a otro expediente.',
      coffin: 'El QR del ataud no es correcto.',
      deceased: 'El QR del fallecido no es correcto.',
      differentValue: 'No corresponde con el apropiado.',
      personalItems: 'El QR de los objetos personales no es correcto.',
      urn: 'El QR de la urna no es correcto.',
    },
    mismatch: 'El QR informado de objetos personales no coincide con el qr de objetos personales asociado al difunto',
    occupied: {
      coffin: 'El QR de feretro ya se ha utilizado en este o en otro expediente',
      deceased: 'El QR de pulsera ya se ha utilizado en este u otro expediente.',
      personalitems: 'El QR de objetos personales ya se ha utilizado en este u otro expediente.',
      urn: 'El QR de urna ya se ha utilizado en este o en otro expediente.',
    },
    personalitems: {
      empty: 'El QR de objetos personales no informado',
      mismatch: 'El QR informado de objetos personales no coincide con el qr de objetos personales asociado al difunto',
    },
    personalItemsQr: 'Escanee el QR asociado a los objetos personales',
    personalItemsQrs: 'Escanee los QR asociados a los objetos personales',
    urn: {
      empty: 'QR de urna no informado',
      occupied: 'QR informado de urna pertenece a otro difunto',
    },
    urnQr: 'Escanee el QR asociado a la urna',
  },
  qrs: {
    mismatch: 'El QR informado de objetos personales no coincide con el qr de objetos personales asociado al difunto',
  },
  record: 'Expediente',
  remove: 'Eliminar orden manual',
  removed: '¡Orden eliminada correctamente!',
  removeError: 'Error al intentar eliminar la orden',
  removeSure: '¿Estás seguro que quieres eliminar la orden?',
  selectPriority: 'Filtrar por prioridad',
  showAll: 'Mostrar todas',
  started: '¡Orden comenzada con éxito!',
  startOrder: 'Comenzar orden',
  status: {
    completed: 'Finalizadas',
    inProgress: 'En curso',
    pending: 'Pendiente',
  },
  types: {
    CEREMONIA: 'Ceremonia',
    INCINERACION: 'Incineración',
    INHUMACION: 'Inhumación',
    INTERNAL_MOVEMENTS: 'Movimiento interno',
    LAPIDA: 'Lápida',
    MANUAL_CEMENTERIO: 'Manual cementerio',
    MANUAL_TALLER: 'Manual taller',
    MOVIMIENTO: 'Movimiento',
    MOVIMIENTO_INTERNO: 'Movimiento interno',
    POST_CEREMONIA: 'Post-Cerimònia',
    PRACTICA_SANITARIA: 'Práctica sanitária',
    PREPARACION: 'Preparación',
    PREPARACION_NICHO: 'Preparación nicho',
    RECEPTION: 'Recepción',
    RECOGIDA: 'Recogida',
    TRANSPORTE: 'Trasllat',
    TRASLADO: 'Traslado',
    TRASLADO_RESTOS: 'Traslado restos',
  },
  updated: '¡Orden actualizada!',
  updateError: 'Error al intentar actualizar la orden',
  urn: {
    deceased: {
      mismatch: 'El QR informado de urna no coincide con el qr de urna asociado al difunto',
    },
  },
  usersError: 'Hubo un problema al intentar actualizar los usuarios asignados',
  usersUpdated: '¡Usuarios asignados actualizados con éxito!',
  vehicle: 'Vehículo',
  workOrders: 'Órdenes de trabajo',
};

export default order;
