import React, {
  FC, Fragment, useEffect, useState,
} from 'react';
import { User, LogOut } from 'react-feather';
import { useTranslation } from 'react-i18next';

import config from 'config';

import defaultImage from 'assets/images/defaultImage.png';

import { usePermissions } from 'utils/usePermissions';

import Dropdown from 'components/base/Dropdown';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { useProvidedAuth } from 'hooks/useProvidedAuth';
import useUrlTranslation from 'hooks/useUrlTranslation';
import './index.scss';
import { Base64Data } from 'models/Base64Data';
import { MimeTypes } from 'models/Base64Data/MimeTypes';

import CustomIcon from '../CustomIcon';
import { OtherTypes } from '../CustomIcon/types';

const Topbar: FC = () => {
  const { translation } = useUrlTranslation();
  const { hasPermission } = usePermissions();
  const [header, setHeader] = useState<string>('');
  const { exit, user } = useProvidedAuth();
  const { t } = useTranslation();
  const [imagePreview, setImagePreview] = useState<string>('');
  const [settingsEnabled, setSettingsEnabled] = useState<boolean>(false);

  useEffect(() => {
    setHeader(translation);
  }, [translation]);

  useEffect(() => {
    if (user?.image) {
      setImagePreview(`data:${MimeTypes.IMAGE_PNG}${Base64Data.BASE64_GLU}${user?.image}`);
    } else {
      setImagePreview('');
    }
  }, [user?.image]);

  useEffect(
    () => {
      setSettingsEnabled(hasPermission(SimpleUserRequestPermissionNames.WHATSAPP_SETTINGS));
    },
    [hasPermission],
  );

  return (
    <div className="funus-topbar">
      <div className="funus-topbar-collapsable">
        <div className="topbar-left">{header}</div>
        <div className="topbar-right">
          <Dropdown
            buttonContent={(
              <Fragment>
                {imagePreview ? (
                  <img alt="Add icon" className="image" src={imagePreview} />
                ) : (
                  <img alt={user?.name || 'user-profile'} src={defaultImage} />
                )}
                <div>
                  <span>
                    {`${user?.name} ${user?.firstSurname || ''} ${
                      user?.secondSurname || ''
                    }`}
                  </span>
                  <span>{t(`role.${user?.role?.name}`, user?.role.name)}</span>
                </div>
              </Fragment>
            )}
            className="user-info"
            options={[
              {
                icon: <User />,
                key: 'profile',
                text: `${t('menu.userProfile')}`,
                url: config.url.userProfile,
              },
              ...settingsEnabled ? [
                {
                  icon: <CustomIcon icon={OtherTypes.BUDGET_CONFIG} />,
                  key: 'settings',
                  text: `${t('menu.settings')}`,
                  url: config.url.settings,
                },
              ] : [],
              {
                color: 'transparent',
                icon: <LogOut />,
                key: 'logout',
                onClick: exit,
                text: `${t('menu.logout')}`,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Topbar;
