import React, {
  FC, useCallback, useEffect, useState,
} from 'react';

import { SettingsForm } from 'modules/settings/components/settings-form';
import { useSettings } from 'modules/settings/hooks/useSettings';
import { IFunusSettings } from 'modules/settings/models/IFunusSettings';

import './index.scss';

type ISettingsPage = Record<string, unknown>

export const SettingsPage: FC<ISettingsPage> = () => {
  const { settings, loadSettings, saveSettings } = useSettings();
  const [workingSettings, setworkingSettings] = useState<IFunusSettings>();

  useEffect(() => {
    setworkingSettings(settings);
  }, [settings]);

  useEffect(
    () => {
      loadSettings();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSubmit = useCallback(
    () => {
      if (workingSettings) {
        saveSettings(workingSettings);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workingSettings],
  );

  return (
    <div id="settings-page">
      {workingSettings && (
        <SettingsForm
          value={workingSettings}
          onChange={(v) => setworkingSettings(v)}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};
