import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorToast, showSuccessToast } from 'utils/toasts';
import { Nullable } from 'utils/types/Nullable/types';

import { deliveryObjectsController } from 'config/apiFunus';
import { QRType } from 'models/QRType';

import { DeliveryObjectsHook } from './types';

export const useDeliveryObjects = (): DeliveryObjectsHook => {
  const { t } = useTranslation();
  const [deliveryObjects, setDeliveryObjects] = useState<Nullable<Array<string>>>(null);

  const updateDeliveryObjects = useCallback(
    (recordId: string, qrs: Array<string>) => deliveryObjectsController
      .updateDeliveryObjects({
        qr: qrs
          .map((qr) => ({
            content: qr,
            type: QRType.PERSONAL_ITEM,
          })),
        recordId,
      })
      .then((response) => {
        showSuccessToast(t('record.objectsDelivered'));

        return response;
      })
      .catch((error) => {
        showErrorToast(t(error.message));
        throw error;
      }), [t],
  );

  const getDeliveryObjects = useCallback((recordId: string) => {
    deliveryObjectsController
      .getDeliveryObjects({ recordId })
      .then((response) => {
        setDeliveryObjects(response.map((qr) => qr.content));
        return response;
      })
      .catch(() => false);
  }, []);

  return {
    deliveryObjects,
    getDeliveryObjects,
    updateDeliveryObjects,
  };
};
