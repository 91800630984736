const qr = {
  coffinQr: 'QR Ataud',
  occupied: {
    coffin: 'El QR de feretro ya se ha utilizado en este o en otro expediente',
    deceased: 'El QR de pulsera ya se ha utilizado en este u otro expediente.',
    personalitems: 'El QR de objetos personales ya se ha utilizado en este u otro expediente.',
    urn: 'El QR de urna ya se ha utilizado en este o en otro expediente.',
  },
  personalItemsQr: 'QR objetos personales',
  qr: 'QR Pulsera',
  search: 'Buscar consultas',
  title: 'Consultas',
  urnQr: 'QR Urna',
};

export default qr;
