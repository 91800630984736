import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { FormikProps } from 'formik';
import React, {
  FC,
  Fragment,
  ReactElement,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import config from 'config';

import { formatDate } from 'utils/dateManager';
import {
  checkHaveAtLeastOnePermission,
  checkHaveThisPermissions,
  checkPermission,
} from 'utils/permissions';
import { showErrorToast, showSuccessToast } from 'utils/toasts';

import CustomIcon from 'components/base/CustomIcon';
import { FeatherIconTypes, OtherTypes } from 'components/base/CustomIcon/types';
import FilteredPage from 'components/base/FilteredPage';
import Loader from 'components/base/Loader';
import Modal from 'components/base/Modal';
import { displayNoValue } from 'components/base/NoData';
import SideFilterField from 'components/base/SideFilters/SideFilterField';
import Table from 'components/base/Table';
import { TableActionsProps } from 'components/base/Table/ActionsColumn/types';
import { TableColumn } from 'components/base/Table/types';

import { PrintComercialModal } from 'components/pages/articles/printComercialModal';
import { DateTimeInfo } from 'components/pages/Information/informationTable/DateTimeInfo';
import AssignAdviser from 'components/record/AssignAdviser';
import DeliveryObjectsModal from 'components/record/DeliveryObjects';
import CreateUpdate from 'components/workOrder/CreateUpdate';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { AshesInfoResponseDto } from 'config/apiFunus/record/types';
import { useCities } from 'hooks/useCities';
import { useFeatures } from 'hooks/useFeatures/useFeatures';
import { useProvidedAuth } from 'hooks/useProvidedAuth';
import useUsers, { mapSimpleUserToMasterData } from 'hooks/useUsers';
import { i18n } from 'i18n';
import { IMasterDataDto } from 'models/MasterData';
import { StatusCodes } from 'models/OrderStatus';
import Record, { DeathType, RecordStateEnum, SearchRecordProps } from 'models/Record';
import { FullUser } from 'models/User';
import { RootState } from 'store';

import './index.scss';
import { AppointmentModal } from '../appointmentModal';
import { AshesDeliveryModal } from '../ashesDeliveryModal';
import { ConfirmDeleteRecordModal } from '../confirmDelete';

const fields = (
  formikProps: FormikProps<SearchRecordProps>,
  recordTypes: IMasterDataDto[],
  assessors: IMasterDataDto[],
): ReactElement[] => [
  <SideFilterField
    key="erpId"
    filterType="input"
    formikProps={formikProps}
    name="erpId"
    placeholder={`${i18n.t('record.number')}`}
  />,
  <SideFilterField
    key="death.deathDate"
    filterType="date"
    formikProps={formikProps}
    name="death.deathDate"
    placeholder={`${i18n.t('common.date')}`}
  />,
  <SideFilterField
    key="deceased.name"
    filterType="input"
    formikProps={formikProps}
    name="deceased.name"
    placeholder={`${i18n.t('common.name')}`}
  />,
  <SideFilterField
    key="deceased.firstSurname"
    filterType="input"
    formikProps={formikProps}
    name="deceased.firstSurname"
    placeholder={`${i18n.t('common.firstSurname')}`}
  />,
  <SideFilterField
    key="deceased.secondSurname"
    filterType="input"
    formikProps={formikProps}
    name="deceased.secondSurname"
    placeholder={`${i18n.t('common.secondSurname')}`}
  />,
  <SideFilterField<SearchRecordProps, IMasterDataDto>
    key="type"
    filterType="select"
    formikProps={formikProps}
    getLabel={({ description }) => description}
    getValue={({ code }) => code}
    name="type"
    options={recordTypes}
    placeholder={`${i18n.t('record.type')}`}
  />,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <SideFilterField<any, RecordStateEnum>
    key="state"
    filterType="select"
    formikProps={formikProps}
    getLabel={(label) => i18n.t(`record.states.${label}`)}
    getValue={(value) => value}
    name="state"
    options={Object.values(RecordStateEnum)}
    placeholder={`${i18n.t('record.state')}`}
    clearable
  />,
  <SideFilterField<SearchRecordProps, IMasterDataDto>
    key="assignedAdviser"
    filterType="select"
    formikProps={formikProps}
    getLabel={({ description }) => description}
    getValue={({ code }) => code}
    name="assignedAdviser"
    options={assessors}
    placeholder={`${i18n.t('record.advisers')}`}
  />,
  <SideFilterField
    key="comment"
    filterType="input"
    formikProps={formikProps}
    name="comment"
    placeholder={`${i18n.t('common.observations')}`}
  />,
];

const canEdit = (row: Record, user?: FullUser): boolean => {
  if (user) {
    return true;
  }
  return !!row;
};

type RecordTableProps = {
  actions?: (row: Record, index: number) => TableActionsProps;
  assignCallback?: () => void;
  columns?: TableColumn<Record>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getData?: (page?: any, size?: any) => Promise<any>;
  setIsLoading?: (loading: boolean) => void;
  showOnlyTable?: boolean;
  pagination?: boolean;
};

const RecordTable: FC<RecordTableProps> = ({
  actions,
  assignCallback,
  columns,
  getData,
  setIsLoading,
  showOnlyTable,
  pagination = false,
}) => {
  const { t } = useTranslation();
  const [totalPages, settotalPages] = useState<number>(1);
  const [closeModal, setCloseModal] = useState<Record>();
  const [loadingClose, setLoadingClose] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showPersonalObjects, setShowPersonalObjects] = useState<boolean>(false);
  const [record, setRecord] = useState<Record>();
  const [initialValues, setInitialValues] = useState({
    deceased: {},
    declarant: {},
  });
  const [data, setData] = useState<Record[]>([]);
  const [localColumns, setLocalColumns] = useState<TableColumn<Record>[]>([]);
  const { user } = useProvidedAuth();
  const { recordTypes } = useSelector(
    (state: RootState) => state.masterData,
  );
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const { canViewComercialPrintable } = useFeatures();
  const [showPrintComercialModal, setShowPrintComercialModal] = useState<boolean>(false);
  const [showAssignAdviserModal, setshowAssignAdviserModal] = useState<boolean>(false);
  const [showAppointmentModal, setShowAppointmentModal] = useState<boolean>(false);
  const [showAshesDeliveryModal, setShowAshesDeliveryModal] = useState<boolean>(false);
  const [ashesDeliveryInfo, setAshesDeliveryInfo] = useState<AshesInfoResponseDto>();

  const { users } = useUsers([SimpleUserRequestPermissionNames.ADVICE_ADD]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CityName = ({ country, province, city }: any) => {
    const { cities, getCityByNormalizedCode } = useCities();
    const [c, setCity] = useState<IMasterDataDto>();

    useEffect(() => {
      setCity(getCityByNormalizedCode(
        country,
        province,
        city,
      ));
    }, [cities, country, province, city, getCityByNormalizedCode]);

    return (
      <React.Fragment>
        {c?.description || displayNoValue}
      </React.Fragment>
    );
  };

  useEffect(() => {
    setLocalColumns(
      columns || [
        {
          accessor: 'id',
          Header: 'ID',
          hidden: true,
        },
        {
          accessor: 'erpId',
          className: 'primary-dark-color',
          Header: `${t('record.number')}`,
          sortable: true,
        },
        {
          accessor: 'death.deathDate',
          Cell: ({
            row: {
              original: { deathData },
            },
          }) => {
            if (deathData?.deathDate) {
              return formatDate(deathData.deathDate);
            }
            return '';
          },
          Header: `${t('record.deathData.deathDate')}`,
          sortable: true,
        },
        {
          accessor: 'deceased.name',
          Cell: ({
            row: {
              original: { deceasedData },
            },
          }) => (!!deceasedData
            && classNames(
              deceasedData.name,
              deceasedData.firstSurname,
              deceasedData.secondSurname,
            ))
            || '',
          Header: `${t('common.name')}`,
          sortable: true,
          width: '50%',
        },
        {
          accessor: 'negotiators',
          Cell: ({ cell: { value } }) => (
            <Fragment>
              <div>
                {value && value[0]
                  ? `${value[0]?.firstSurname}, ${value[0]?.name}`
                  : ''}
              </div>
              <div>
                {value && value[1]
                  ? `${value[1]?.firstSurname}, ${value[1]?.name}`
                  : ''}
              </div>
            </Fragment>
          ),
          Header: `${t('record.advisers')}`,
        },
        {
          accessor: 'deathData',
          Cell: ({ cell: { value } }) => (
            <CityName
              city={value?.address?.city}
              country={value?.address?.country}
              province={value?.address?.province}
            />
          ),
          Header: `${t('common.city')}`,
          sortable: false,
        },
        {
          accessor: 'appointmentDateTime',
          Cell: ({ cell: { value } }) => (
            value ? <DateTimeInfo date={value} time={value} /> : '--'
          ),
          Header: `${t('record.appointment')}`,
          sortable: false,
        },
      ],
    );
  }, [t, columns]);

  const openModal = (
    row: Record,
    setModal: Dispatch<SetStateAction<boolean>>,
  ) => {
    setRecord(row);
    setModal(true);
  };

  const setLoading = (loading: boolean) => {
    if (setIsLoading) {
      setIsLoading(loading);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const addAppointment = (id: string, dateTime: Date | null, callBack: any) => {
    config.apiFunus.record.appointment(id, dateTime)
      .then(() => {
        setShowAppointmentModal(false);
        callBack?.();
        return false;
      })
      .catch(() => {
        showErrorToast(i18n.t('record.addAppointmentKo'));
      });
  };

  const displayAshesDelivery = async (recordId: string | number) => {
    await config.apiFunus.record.getAshesDelivery(recordId)
      .then((response: AxiosResponse<AshesInfoResponseDto>) => {
        setAshesDeliveryInfo(response.data);
        setShowAshesDeliveryModal(true);
        return true;
      })
      .catch(() => false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateAshesDelivery = async (id: any, d: any, callBack?: any) => {
    await config.apiFunus.record.updateAshesDelivery(id, d)
      .then(() => {
        showSuccessToast(t('record.ashesDeliveryOk'));
        setShowAshesDeliveryModal(false);
        callBack?.();
        return true;
      })
      .catch(() => {
        showErrorToast(t('record.ashesDeliveryKo'));
      });
  };

  const getActions = (row: Record): TableActionsProps => {
    let result: TableActionsProps = { extra: [] };

    const isFinished = row.state === RecordStateEnum.FINISHED;

    const hasRecordReadWrite = checkHaveThisPermissions(
      [
        SimpleUserRequestPermissionNames.RECORD_READ,
        SimpleUserRequestPermissionNames.RECORD_WRITE,
      ],
      user?.role.permissions,
    );
    const hasAdviceWriteAdd = checkHaveAtLeastOnePermission(
      [
        SimpleUserRequestPermissionNames.ADVICE_WRITE,
        SimpleUserRequestPermissionNames.ADVICE_ADD,
      ],
      user?.role.permissions,
    );
    const hasRecordRead = checkPermission(
      SimpleUserRequestPermissionNames.RECORD_READ,
      user?.role.permissions,
    );
    const hasRecordWrite = checkPermission(
      SimpleUserRequestPermissionNames.RECORD_WRITE,
      user?.role.permissions,
    );
    const hasRecordAssign = checkPermission(
      SimpleUserRequestPermissionNames.RECORD_ASSIGN,
      user?.role.permissions,
    );
    const hasAppointment = checkPermission(
      SimpleUserRequestPermissionNames.APPOINTMENT,
      user?.role.permissions,
    );
    const hasCemeteryWorkshopRead = checkHaveAtLeastOnePermission(
      [
        SimpleUserRequestPermissionNames.WORK_ORDER_CEMETERY_READ,
        SimpleUserRequestPermissionNames.WORK_ORDER_WORKSHOP_READ,
      ],
      user?.role.permissions,
    );
    const hasCemeteryWorkshopAdd = checkHaveAtLeastOnePermission(
      [
        SimpleUserRequestPermissionNames.WORK_ORDER_CEMETERY_ADD,
        SimpleUserRequestPermissionNames.WORK_ORDER_WORKSHOP_ADD,
      ],
      user?.role.permissions,
    );

    if (
      hasRecordReadWrite
      && canEdit(row, user)
      && !isFinished
    ) {
      result = {
        ...result,
        edit: {
          icon: <CustomIcon icon={FeatherIconTypes.EDIT} />,
          tooltipCaption: t('common.edit'),
          url: `${config.url.records}/${row.id}/edit`,
        },
        extra: [
          ...(result.extra || []),
        ],
      };
    }

    if (hasAdviceWriteAdd) {
      result = {
        ...result,
        seeServices: {
          icon: <CustomIcon icon={FeatherIconTypes.SETTINGS} />,
          tooltipCaption: t('menu.detailservices'),
          url: `${config.url.services}/${row.id}`,
        },
      };

      if (row.state === RecordStateEnum.INVOICED_PENDING) {
        result = {
          ...result,
          close: {
            icon: <CustomIcon icon={FeatherIconTypes.CHECKCIRCLE} />,
            isExtra: true,
            onClick: () => setCloseModal(row),
            tooltipCaption: t('record.close'),
          },
        };
      }
    }

    if (row.ashesDelivery) {
      result = {
        ...result,
        ashesDelivery: {
          callback: assignCallback || undefined,
          icon: <CustomIcon icon={FeatherIconTypes.SEND} />,
          onClick: () => {
            setRecord(row);
            displayAshesDelivery(row.id);
          },
          recordId: row?.id,
          tooltipCaption: t('record.ashesDelivery'),
        },
      };
    }

    if (
      hasRecordRead
      && (!hasRecordWrite || isFinished)
    ) {
      result = {
        ...result,
        extra: [
          ...(result.extra || []),
        ],
        see: {
          icon: <CustomIcon icon={FeatherIconTypes.EYE} />,
          tooltipCaption: t('common.see'),
          url: `${config.url.records}/${row.id}`,
        },
      };
    }

    if (
      hasRecordAssign
      && !isFinished
    ) {
      result = {
        ...result,
        assignRecord: {
          callback: assignCallback || undefined,
          icon: <CustomIcon icon={FeatherIconTypes.USERPLUS} />,
          onClick: () => {
            setRecord(row);
            setshowAssignAdviserModal(true);
          },
          record: row,
          tooltipCaption: t('record.assignAdviser'),
        },
      };
    }

    if (
      hasAppointment
    ) {
      result = {
        ...result,
        appointment: {
          appointmentDateTime: row?.appointmentDateTime,
          icon: <CustomIcon icon={FeatherIconTypes.CLOCK} />,
          isExtra: true,
          onClick: () => {
            setRecord(row);
            setShowAppointmentModal(true);
          },
          recordId: row?.id,
          tooltipCaption: t('record.appointment'),
        },
      };
    }

    if (hasRecordReadWrite
      && canEdit(row, user)
      && !isFinished
    ) {
      result = {
        ...result,
        deliveryObjects: {
          icon: <CustomIcon icon={FeatherIconTypes.PACKAGE} />,
          isExtra: true,
          onClick: () => openModal(row, setShowPersonalObjects),
          tooltipCaption: t('record.deliveryObjects'),
        },
        extra: [
          ...(result.extra || []),
        ],
      };
    }

    if (
      hasCemeteryWorkshopRead
    ) {
      result = {
        ...result,
        seeWorkshop: {
          icon: <CustomIcon icon={FeatherIconTypes.TRELLO} />,
          tooltipCaption: t('menu.workshop'),
          url: `${config.url.workshop}/${row.id}`,
        },
      };
    }

    if (
      hasCemeteryWorkshopAdd
      && !isFinished
    ) {
      result = {
        ...result,
        createWorkOrder: {
          icon: <CustomIcon icon={FeatherIconTypes.FILEPLUS} />,
          isExtra: true,
          onClick: () => openModal(row, setShowCreate),
          tooltipCaption: t('record.createWorkOrder'),
        },
      };
    }

    if (
      row.canDelete
    ) {
      result = {
        ...result,
        recordDelete: {
          icon: <CustomIcon icon={FeatherIconTypes.TRASH2} />,
          isExtra: true,
          onClick: () => {
            setRecord(row);
            setShowDeleteModal(true);
          },
          recordId: row?.erpId,
          tooltipCaption: t('common.remove'),
        },
      };
    }

    if (canViewComercialPrintable(row)) {
      result = {
        ...result,
        viewComercialPrintable: {
          icon: <CustomIcon icon={OtherTypes.PRINT_ARTICLE_COMERCIAL} />,
          isExtra: true,
          onClick: () => {
            setRecord(row);
            setShowPrintComercialModal(true);
          },
          recordId: row?.erpId,
          tooltipCaption: t('common.print'),
        },
      };
    }

    return result;
  };

  const getCreate = () => {
    const hasRecordAddAddNotice = checkHaveAtLeastOnePermission(
      [
        SimpleUserRequestPermissionNames.RECORD_ADD,
        SimpleUserRequestPermissionNames.RECORD_ADD_NOTICE,
      ],
      user?.role.permissions,
    );

    if (
      hasRecordAddAddNotice
    ) {
      return {
        title: t('record.new'),
        url: config.url.newRecord,
      };
    }

    return undefined;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const apiCall = (params: any) => {
    setInitialValues(params);

    const typeFilter = {
      type: (params.filter[0].type as IMasterDataDto)?.code || undefined,
    };
    const adviserFilter = {
      user: {
        id: (params.filter[0].assignedAdviser as IMasterDataDto)?.code || undefined,
      },
    };

    const hasCemeteryReadWrite = checkHaveThisPermissions(
      [
        SimpleUserRequestPermissionNames.WORK_ORDER_CEMETERY_READ,
        SimpleUserRequestPermissionNames.WORK_ORDER_CEMETERY_WRITE,
      ],
      user?.role?.permissions,
    );
    const hasWorkshopReadWrite = checkHaveThisPermissions(
      [
        SimpleUserRequestPermissionNames.WORK_ORDER_WORKSHOP_READ,
        SimpleUserRequestPermissionNames.WORK_ORDER_WORKSHOP_WRITE,
      ],
      user?.role?.permissions,
    );
    const hasRecordRead = checkPermission(
      SimpleUserRequestPermissionNames.RECORD_READ,
      user?.role?.permissions,
    );

    return !((hasCemeteryReadWrite || hasWorkshopReadWrite)
     && hasRecordRead)
      ? config.apiFunus.record.getRecords({
        ...params,
        filter: [
          {
            ...params.filter[0],
            ...typeFilter,
            ...adviserFilter,
          },
        ],
      })
      : config.apiFunus.record.getRecords({
        ...params,
        filter: [
          {
            ...params.filter[0],
            ...typeFilter,
            ...adviserFilter,
          },
        ],
        workOrderExcludedStatus: StatusCodes.COMPLETED,
      });
  };

  const getDataCall = (page = 0, size = 10) => {
    if (getData) {
      setLoading(true);
      getData(page, size)
        .then((response) => {
          setData(response.data.list || response.data);
          settotalPages(response.data.numberOfPages);
          setLoading(false);
          return response;
        })
        .catch(() => {
          showErrorToast(t('record.requestError'));
          setLoading(false);
        });
    }
  };
  const closeRecord = () => {
    if (closeModal?.id) {
      setLoadingClose(true);
      config.apiFunus.record
        .closeRecord(closeModal.id)
        .then((res) => {
          showSuccessToast(t('record.closedOk'));
          setLoadingClose(false);
          setReload(true);
          setCloseModal(undefined);
          setTimeout(() => setReload(false), 100);
          return res;
        })
        .catch(() => {
          showSuccessToast(t('record.closedKo'));
          setLoadingClose(false);
        });
    }
  };

  useEffect(() => {
    if (showOnlyTable) {
      getDataCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOnlyTable]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rowClassName = (index: any, row: any) => (row.deathData?.deathType === DeathType.judicial ? 'is-judicial-death' : '');

  return (
    <Fragment>
      {(loadingClose || reload) && <Loader />}
      <ConfirmDeleteRecordModal
        id={record?.id}
        show={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onSuccess={() => {
          setShowDeleteModal(false);
          setReload(true);
          setTimeout(() => setReload(false), 100);
        }}
      />
      <Modal
        buttons={[
          {
            disabled: loadingClose,
            id: 'accept',
            onClick: closeRecord,
            text: t('common.accept'),
          },
        ]}
        show={!!closeModal}
        title={t('record.close')}
        onHide={() => setCloseModal(undefined)}
      >
        <p>
          {t('record.closeText')}
          {' '}
          <strong>{closeModal?.erpId}</strong>
        </p>
        <p>{t('common.sure')}</p>
      </Modal>
      <CreateUpdate
        recordId={record?.id}
        show={showCreate}
        title={t('order.create')}
        onHide={() => setShowCreate(false)}
      />
      <DeliveryObjectsModal
        recordId={record?.id as number}
        show={showPersonalObjects}
        onHide={() => setShowPersonalObjects(false)}
      />
      {!reload && (
        <Fragment>
          {showOnlyTable ? (
            <Table<Record>
              actions={(row: Record, index: number) => actions?.(row, index) || getActions(row)}
              columns={localColumns}
              data={data}
              fetchData={getDataCall}
              pagination={pagination}
              rowClassName={rowClassName}
              serverSide={{
                filter: true,
                pagination: true,
                sort: false,
              }}
              totalPages={totalPages}
            />
          ) : (
            <FilteredPage<SearchRecordProps, Record>
              actions={(row: Record, index: number) => actions?.(row, index) || getActions(row)}
              apiCall={(params) => apiCall(params)}
              columns={localColumns}
              create={getCreate()}
              fields={(props) => fields(props, recordTypes, users.map(mapSimpleUserToMasterData))}
              initialValues={initialValues}
              rowClassName={rowClassName}
              setIsLoading={setIsLoading}
              text={{
                search: t('record.search'),
                title: t('record.title'),
              }}
            />
          )}
        </Fragment>
      )}
      <PrintComercialModal
        erpId={Number(record?.erpId)}
        show={showPrintComercialModal}
        onHide={() => {
          setShowPrintComercialModal(false);
        }}
      />
      <AssignAdviser
        callBack={() => {
          setReload(true);
          setTimeout(() => setReload(false), 100);
        }}
        record={record}
        show={showAssignAdviserModal}
        onHide={() => setshowAssignAdviserModal(false)}
      />
      {record?.id
        && (
        <AppointmentModal
          dateTime={record?.appointmentDateTime
            ? new Date(record.appointmentDateTime)
            : null}
          recordId={`${record?.id}`}
          show={showAppointmentModal}
          onAccept={(
            recordId: string, dateTime: Date | null,
          ) => addAppointment(recordId, dateTime, () => {
            setReload(true);
            setTimeout(() => setReload(false), 100);
          })}
          onHide={() => setShowAppointmentModal(false)}
        />
        )}
      <AshesDeliveryModal
        ashesDeliveryInfo={ashesDeliveryInfo}
        show={showAshesDeliveryModal}
        onAccept={(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          payload: any,
        ) => updateAshesDelivery(
          record?.id,
          payload,
        )}
        onCancel={() => {
          setShowAshesDeliveryModal(false);
        }}
      />
    </Fragment>
  );
};

export default RecordTable;
