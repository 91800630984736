import { Nullable } from 'utils/types/Nullable/types';

import { ReceptionTypes } from 'hooks/useReceptionTypes';
import Address from 'models/Address';
import BaseModel from 'models/BaseModel';
import { IMasterDataDto } from 'models/MasterData';
import { SimpleUser } from 'models/User';

import { Declarant } from './Declarant';
import { PersonalDataDocumentDto } from './PersonalDataDocumentDto';

export enum LandingRecords {
  ASSIGNED = 'ASSIGNED',
  NOT_ASSIGNED = 'NOT_ASSIGNED',
  ASSIGNED_TO_ME = 'ASSIGNED_TO_ME',
  ASSIGNED_OTHERS = 'ASSIGNED_OTHERS',
}

export enum RecordStateEnum {
  FINISHED = 'FINISHED',
  INVOICED = 'INVOICED',
  INVOICED_PENDING = 'INVOICED_PENDING',
  OPENED = 'OPENED',
}

export enum RecordTypeEnum {
  ABORTION = '2',
  AMPUTATION = '3',
  DEATH = '1',
}

export type AbortionData = {
  comment?: string;
  declarantIsMother?: boolean;
  fatherFirstSurname?: string;
  fatherName?: string;
  fatherSecondSurname?: string;
  gender?: string;
  gestationWeeks?: number;
  type?: string;
};

export type AmputationData = {
  member?: string;
};

export type DeceasedData = {
  age?: string;
  birthAddress?: Address;
  birthday?: Date;
  childrenNames?: string;
  deceasedAddress?: Address;
  fatherName?: string;
  firstSurname?: string;
  fullName?: string;
  gender?: string;
  identifier?: string;
  maritalStatus?: string;
  motherName?: string;
  name?: string;
  nationality?: string;
  nickname?: string;
  nie?: string;
  partnerName?: string;
  passport?: string;
  phone?: string;
  recordNumber?: number;
  secondSurname?: string;
  personalObjects?: string;
  coffinSize?: string;
  defunctStatus?: string;
};

export enum DeathType {
  natural = 'N',
  judicial = 'J',
}

export type DeathData = {
  address?: Address;
  approximatedDate?: Nullable<string>;
  atHome?: boolean;
  comment?: string;
  deathCause?: Nullable<string>;
  deathDate?: Date;
  deathTime?: Nullable<Date>;
  deathType?: DeathType;
  destinationCenter?: string;
  diseases?: Nullable<string>;
  doctor?: string;
  errands?: Nullable<string>;
  location?: Nullable<string>;
  medicalCertificate?: Nullable<boolean>;
  pickupAddress?: Address;
  trialCourt?: Nullable<string>;
};

export type Document = {
  id: string;
  name: string;
  type: string;
};

export type LocalData = {
  description: string;
  localId: string;
};

export type InsuranceCompany = IMasterDataDto & {
  locals: LocalData[];
};

export type Insurance = {
  agent?: Nullable<string>;
  amount?: Nullable<string>;
  companyDelegation?: Nullable<string>;
  companyName?: string;
  hasPolicy?: boolean;
  idPaymentLocation?: string;
  maxCoverage?: Nullable<number>;
  personalObjects?: Nullable<string>;
  policyNumber?: Nullable<string>;
  renounce?: Nullable<boolean>;
  sinister?: Nullable<string>;
};

export type ReceptionData = {
  receptionType: Nullable<ReceptionTypes>;
  entryDate: Nullable<Date>;
  entryTime: Nullable<Date>;
  funeraryOrigin: Nullable<string>;
  observations: Nullable<string>;
  operatorComment: Nullable<string>;
};

export type RecordId = string;

export type SaveRecord = {
  creator?: {
    name: string;
    firstSurname: string;
  };
  abortionData?: Nullable<AbortionData>;
  ageConfidential?: boolean;
  amputationData?: AmputationData;
  center?: string;
  comment?: string;
  collectionComment?: string;
  createdDate?: string;
  deathData?: DeathData;
  deceasedData?: DeceasedData;
  declarantData?: Declarant;
  documents?: Document[];
  erpId?: string | number | undefined;
  hasPickUp?: boolean;
  canCreateReception?: boolean;
  hasReception?: boolean;
  hasWorkOrder?: boolean;
  id?: RecordId;
  insuranceData?: Nullable<Insurance>;
  negotiators?: SimpleUser[];
  publishCeremony?: boolean;
  publishVigil?: boolean;
  sendByEmail?: boolean;
  signature?: string;
  state: RecordStateEnum;
  type?: RecordTypeEnum;
  appointmentDateTime?: string;
  receptionData?: Nullable<ReceptionData>;
  appointmentDate?: string;
  appointmentTime?: string;
  canCreateBudget?: boolean;
  declarantDataDocumentDto?: PersonalDataDocumentDto;
  deceasedDataDocumentDto?: PersonalDataDocumentDto;
};

export type SearchRecordProps = {
  comment?: string | undefined;
  createdDate?: string | undefined;
  date?: string | undefined;
  deceased?: {
    firstSurname?: string | undefined;
    name?: string | undefined;
    secondSurname?: string | undefined;
  };
  declarant?: {
    fullName?: string | undefined;
  };
  id?: string | undefined;
  remarks?: string | undefined;
  state?: RecordStateEnum | undefined;
  type?: IMasterDataDto | string | undefined;
  user?: IMasterDataDto | string | undefined;
};

type Record = BaseModel & {
  ageConfidential?: boolean;
  center?: string;
  comment?: string;
  deathData?: DeathData;
  deceasedData?: DeceasedData;
  declarantData?: Declarant;
  documents?: Document[];
  erpId?: string | number | undefined;
  insuranceData?: Insurance;
  negotiators?: SimpleUser[];
  publishCeremony?: boolean;
  publishVigil?: boolean;
  sendByEmail?: boolean;
  state: RecordStateEnum;
  type?: string;
  appointmentDateTime?: string;
  canDelete?: boolean;
  // workorders?: Order[];
  // FIXME: Remove this fields when we can handle sorting in table
  'deceased.name'?: string;
  'death.deathDate'?: string;
  'deceased.deceasedAddress'?: string;
  ashesDelivery?: boolean;
  hasCommercial?: boolean;
};

export default Record;
