import classNames from 'classnames';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';

import { ISettingsTabs } from './ISettingsTabs';
import { SettingsTabOptions } from './SettingsTabOptions';

import './index.scss';

export const SettingsTabs: FC<ISettingsTabs> = ({ onChange, value }) => {
  const { t } = useTranslation();
  const handleClick = useCallback(
    (v: SettingsTabOptions) => {
      onChange(v);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const settings: Array<SettingsTabOptions> = [
    SimpleUserRequestPermissionNames.WHATSAPP_SETTINGS,
  ];

  return (
    <div className="f-settings-tabs">
      {settings.map((settingsBlock, index) => (
        <div
          key={settingsBlock}
          className={classNames('f-settings-tabs-item', { active: settingsBlock === value })}
          role="tab"
          tabIndex={index}
          onClick={() => {
            handleClick(settingsBlock);
          }}
          onKeyDown={() => false}
        >
          {t(`settings.blocks.${settingsBlock}`)}
        </div>
      ))}
    </div>
  );
};
