import React, {
  FC, useCallback, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/base/Modal';

import './index.scss';
import { DeliveryObjectContent } from './DeliveryObjectContent';
import { useDeliveryObjects } from './useDeliveryObjects';

type DeliveryObjectsProps = {
  recordId: number;
  show: boolean;
  onHide: () => void;
};

const DeliveryObjectsModal: FC<DeliveryObjectsProps> = ({
  recordId,
  show,
  onHide,
}) => {
  const { t } = useTranslation();
  const { updateDeliveryObjects } = useDeliveryObjects();
  const [dataQR, setDataQR] = useState<Array<string>>([]);

  const sendDeliveredObjects = useCallback(
    () => {
      updateDeliveryObjects(recordId.toString(), dataQR)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          closeModal();

          return response;
        }).catch(() => false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataQR, recordId],
  );

  const closeModal = () => {
    onHide();
    setDataQR([]);
  };

  const hasRepeatedQR = useMemo(
    () => new Set(dataQR).size !== dataQR.length,
    [dataQR],
  );

  return (
    <Modal
      buttons={[
        {
          color: 'primary',
          disabled: hasRepeatedQR,
          id: 'accept',
          onClick: sendDeliveredObjects,
          text: t('common.accept'),
          type: 'button',
        },
      ]}
      closeOnFocusLost={false}
      show={show}
      title={t('record.deliveryObjects')}
      onHide={closeModal}
    >
      {show
      && (
        <React.Fragment>
          <DeliveryObjectContent
            recordId={recordId.toString()}
            onCancel={() => {
              onHide();
            }}
            onChange={(qr) => {
              setDataQR(qr);
            }}
          />
          {hasRepeatedQR && <div className="text-error">{t('record.deliveryObjectsRepeated')}</div>}
        </React.Fragment>
      )}
    </Modal>
  );
};

export default DeliveryObjectsModal;
